import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FileItemDto, UploadItem } from '@backend/models';
import { BlobUploadCommonResponse, BlockBlobClient } from '@azure/storage-blob';
import { useApi } from '@backend/api';
import { getFilesFromEvent } from '../../utils/uploader.utils';

export interface LoadedBytes {
  totalBytes: number;
  loadedBytes: number;
  finished: boolean;
  canceled: boolean;
}

export interface UploadProgress {
  totalBytes: number;
  loadedBytes: number;
  percentage: number;
  totalFiles: number;
  loadedFiles: number;
  publishedFiles: number;
}

const config = {
  blockSize: 4 * 1024 * 1024, // 4MB block size
  concurrency: 20, // 20 concurrency
};

interface FileX {
  file: File;
}

let uploadIndex = 0;

export const useUploader = (
  getAlbumId: () => Promise<number>,
  progressCallback: ((progress: UploadProgress) => void) | undefined
) => {
  const [draggedElements, setDraggedElements] = useState<DataTransferItem[]>();
  const [active, setActive] = useState(false);
  const dragTimeout = useRef<any>();
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const loadedBytesRef = useRef<{ [key: string]: LoadedBytes }>({});
  const publishedFileRef = useRef<{ [key: string]: boolean }>({});
  const [publishingInProgress, setPublishingInProgress] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(1);
  const api = useApi();
  const [firstFile, setFirstFile] = useState<File>();

  const handleDragEnter = useCallback(
    async (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const dragged = getFilesFromEvent(e) as DataTransferItem[];
      setDraggedElements(dragged);
    },
    []
  );

  const handleDragOver = useCallback(
    async (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      clearTimeout(dragTimeout.current);
      const dragged = getFilesFromEvent(e) as DataTransferItem[];
      setDraggedElements(dragged);
      setActive(true);
    },
    []
  );

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // prevents repeated toggling of `active` state when file is dragged over children of uploader
    // see: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    dragTimeout.current = setTimeout(() => {
      setDraggedElements([]);
      setActive(false);
    }, 150);
  }, []);

  const zz = useCallback(() => {
    if (loadedBytesRef.current) {
      let totalBytes = 0;
      let loadedBytes = 0;
      let totalFiles = 0;
      let loadedFiles = 0;

      for (const [key, f] of Object.entries(loadedBytesRef.current)) {
        if (f.canceled) {
          continue;
        }
        totalBytes += f.totalBytes;
        totalFiles++;
        if (f.finished) {
          loadedFiles++;
          loadedBytes += f.totalBytes;
        } else {
          loadedBytes += f.loadedBytes;
        }
      }
      if (totalBytes === 0) {
        totalBytes = 1;
      }
      const progress = {
        totalBytes: totalBytes,
        loadedBytes: loadedBytes,
        percentage: Math.ceil((loadedBytes / totalBytes) * 100),
        totalFiles: totalFiles,
        loadedFiles: loadedFiles,
        publishedFiles: Object.values(publishedFileRef.current).length,
      };

      if (progressCallback) {
        progressCallback(progress);
      }
    }
  }, [loadedBytesRef, publishedFileRef, progressCallback]);

  useEffect(() => {
    if (!isUploading || isFinished) {
      return;
    }
    const timer = setInterval(() => {
      zz();
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, [isFinished, zz, isUploading]);

  const handleFiles = useCallback(
    async (files: File[]) => {
      loadedBytesRef.current = {};
      publishedFileRef.current = {};

      const albumId = await getAlbumId();
      if (!albumId || albumId === -1 || !files.length) {
        return;
      }

      const abortController = new AbortController();
      const requestFiles: FileItemDto[] = [];
      const map: { [key: string]: FileX } = {};
      for (const file of files) {
        uploadIndex++;
        const key = 'k' + uploadIndex;
        map[key] = {
          file: file,
        };
        const lastModified = new Date(file.lastModified);
        requestFiles.push({
          key: key,
          challengeHandle: undefined,
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          lastModified: lastModified as any,
        });
        loadedBytesRef.current[key] = {
          totalBytes: file.size,
          loadedBytes: 0,
          canceled: false,
          finished: false,
        };
      }

      setIsUploading(true);
      setPublishingInProgress(true);
      setIsFinished(false);
      setFirstFile(files[0]);
      const result = await api.uploadApiService.getSas_POST({
        files: requestFiles,
        albumId: albumId,
      });

      const checkHandles = result.result?.files?.map((x) => x.handle) || [];

      let finishedUploading = false;
      let finishedItems: UploadItem[] = [];
      const intervall = setInterval(() => {
        const finished = finishedItems;
        finishedItems = [];
        api.uploadApiService
          .finished_POST({
            items: finished,
            checkHandles: checkHandles,
          })
          .then((r) => {
            const handles = r.result?.handles || [];
            let newProcessed = false;
            for (const handle of handles) {
              const idx = checkHandles.indexOf(handle);
              if (idx > -1) {
                checkHandles.splice(idx, 1);
                newProcessed = true;
                publishedFileRef.current[handle] = true;
              }
            }
            if (newProcessed) {
              setReloadTrigger((prev) => prev + 1);
              zz();
            }
            if (finishedUploading) {
              clearInterval(intervall);
              setIsFinished(true);
              setPublishingInProgress(false);
            }
          });
      }, 3 * 1000);
      const sasFiles = result.result?.files || [];

      const filesFinished = 0;
      const promises: Promise<BlobUploadCommonResponse>[] = [];
      for (const sasFile of sasFiles) {
        const file = map[sasFile.key];
        const client = new BlockBlobClient(sasFile.sasUrl);
        const promise = client
          .uploadData(file.file, {
            abortSignal: abortController.signal,
            blockSize: config.blockSize,
            concurrency: config.concurrency,
            onProgress: (progress) => {
              if (!loadedBytesRef.current[sasFile.key]) {
                loadedBytesRef.current[sasFile.key] = {
                  totalBytes: 0,
                  loadedBytes: 0,
                  canceled: false,
                  finished: false,
                };
              }
              loadedBytesRef.current[sasFile.key].loadedBytes =
                progress.loadedBytes;
            },
          })
          .then((r) => {
            loadedBytesRef.current[sasFile.key].finished = true;
            finishedItems.push({
              handle: sasFile.handle,
              type: sasFile.type,
              uploadSeconds: 0,
            });
            return r;
          });
        promises.push(promise);
      }
      await Promise.all(promises).then(() => {
        setIsUploading(false);
        zz();
      });
      finishedUploading = true;
    },
    [zz, getAlbumId, api.uploadApiService]
  );

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDraggedElements([]);
      setActive(false);

      const files = getFilesFromEvent(e) as File[];
      handleFiles(files).then(() => {});
    },
    [handleFiles]
  );

  const dragProps = useMemo(() => {
    return {
      onDragEnter: handleDragEnter,
      onDragOver: handleDragOver,
      onDragLeave: handleDragLeave,
      onDrop: handleDrop,
    };
  }, [handleDragEnter, handleDragOver, handleDragLeave, handleDrop]);

  return {
    dragProps: dragProps,
    handleFiles: handleFiles,
    publishingInProgress: publishingInProgress,
    isUploading: isUploading,
    isFinished: isFinished,
    reloadTrigger: reloadTrigger,
    dropZoneRef: dropZoneRef,
    firstFile: firstFile,
  };
};
