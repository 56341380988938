let lasClick = 0;

export const clickUpload = () => {
  const now = new Date().getTime();
  if (now - lasClick < 600) {
    return;
  }
  lasClick = now;
  const el = document.getElementById('my-uploader-button');
  if (el) {
    el.click();
  } else {
    setTimeout(() => {
      const el = document.getElementById('my-uploader-button');
      if (el) {
        el.click();
      }
    }, 100);
  }
};
