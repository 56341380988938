import React from 'react';
import './galleries.scss';
import { AlbumSelection } from '../../components/album-selection/AlbumSelection';
import { useEventContext } from '@event-images/shared';

export interface GalleriesProps {}

export const Galleries = (props: GalleriesProps) => {
  const { setCurrentTitle, albums } = useEventContext();

  if (!albums) {
    return null;
  }
  return (
    <div className={'startpage'}>
      <div className={'header-title'}>Foto-Alben</div>
      <AlbumSelection albums={albums} finished={() => {}} />
    </div>
  );
};
