import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import * as PhoneInput from 'react-phone-input-2';

export interface ConfirmModalResult {
  phoneNumber: string | undefined;
}

export interface ConfirmModalProps {
  title?: string;
  text?: string;
  okText?: string;
  cancelText?: string;
  open?: boolean;
  onCancel?: () => void;
  onFinish?: (val: ConfirmModalResult) => Promise<void>;
  showPhoneNumber?: boolean;
  phoneNumber?: string;
}

export const ConfirmModal = (props: ConfirmModalProps) => {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    props.phoneNumber || ''
  );
  useEffect(() => {
    setPhoneNumber(props.phoneNumber || '');
  }, [props.phoneNumber]);

  return (
    <Dialog
      onClose={props?.onCancel}
      open={!!props?.open}
      className={'my-upload-dialog my-confirm-dialog'}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          if (props?.onCancel) {
            props.onCancel();
          }
        }}
        className={'close'}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{props.title || 'Achtung'}</DialogTitle>

      <DialogContent>
        <div>
          {props.text || 'Vorgang fortsetzen?'}
          {props.showPhoneNumber && (
            <div className={'cropper-controls-1'}>
              <div className={'logoutphone'}>
                Ohne Angabe einer Telefonummer verlierst du bei Logout den
                Zugriff auf deine Fotos und Kommentare.
              </div>
              <PhoneInput.default
                country={'at'}
                value={phoneNumber || ''}
                onlyCountries={['at', 'rs', 'de']}
                placeholder={'+43 664 123 456 78'}
                areaCodes={[]}
                onChange={(e) => {
                  setPhoneNumber(e);
                }}
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (props?.onCancel) {
              props.onCancel();
            }
          }}
        >
          {props.cancelText || 'Abbrechen'}
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={() => {
            if (props?.onFinish) {
              setLoading(true);
              props
                .onFinish({
                  phoneNumber: phoneNumber,
                })
                .then(() => {
                  setLoading(false);
                });
            }
          }}
        >
          {props.okText || 'Ok'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
