import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './uploader-modal.scss';
import { useApi } from '@backend/api';
import { UploadProgress, useUploader } from './useUploader';
import UploadSvg from '../../svg/upload.svg';
import { AlbumDto } from '@backend/models';
import Lottie from 'lottie-react';
import heartSuccess from '../../lottie/hearts.json';
import { getFilesFromEvent } from '../../utils/uploader.utils';
import { CropResult } from '../cropper/cropper.models';
import { useEventContext } from '../../contexts/EventContext';
import { useUserContext } from '../../contexts/UserContext';
import { AlbumSelection } from './AlbumSelection';
import { clickUpload } from '../../clickutils';

export interface UploaderModalProps {
  open?: boolean;
  albumId?: number;
  onClose?: () => void;
  onFinish?: () => void;
}

export const UploaderModal = (props: UploaderModalProps) => {
  const api = useApi();
  const { onFinish } = props;

  const [selectedAlbumId, setSelectedAlbumId] = useState(
    props.albumId ? props.albumId * 1 : undefined
  );
  const [uploadProgress, setUploadProgress] = useState<UploadProgress>();
  const [finishAnimation, setFinishAnimation] = useState(false);
  const { albums } = useEventContext();
  useEffect(() => {
    setSelectedAlbumId(props.albumId ? props.albumId * 1 : undefined);
  }, [props.albumId]);

  const progressCallback = useCallback((progress: UploadProgress) => {
    setUploadProgress(progress);
  }, []);

  const getAlbumId = useCallback(async () => {
    return selectedAlbumId || 0;
  }, [selectedAlbumId]);

  const {
    isUploading,
    isFinished,
    dragProps,
    handleFiles,
    publishingInProgress,
    firstFile,
  } = useUploader(getAlbumId, progressCallback);

  const onFinishX = useCallback(() => {
    setFinishAnimation(true);
    setTimeout(() => {
      if (onFinish) {
        onFinish();
      }
    }, 10 * 1000);
  }, [onFinish]);

  useEffect(() => {
    if (!isFinished) {
      return;
    }
    onFinishX();
    return;
  }, [api, firstFile, onFinishX, isFinished, selectedAlbumId, onFinish]);

  const handleClose = () => {
    if (isUploading) {
      return;
    }
    if (props.onClose) {
      props.onClose();
    }
  };
  if (!albums) {
    return null;
  }

  return (
    <Dialog
      onClose={handleClose}
      open={!!props.open}
      className={'my-upload-dialog'}
    >
      <IconButton aria-label="close" onClick={handleClose} className={'close'}>
        <CloseIcon />
      </IconButton>
      {!selectedAlbumId && <DialogTitle>Wähle das Album</DialogTitle>}
      {selectedAlbumId && (
        <DialogTitle>
          Hochladen in{' '}
          {albums?.find((x) => x.albumId == selectedAlbumId)?.title}
        </DialogTitle>
      )}

      <DialogContent key={'s' + selectedAlbumId}>
        {!selectedAlbumId && (
          <AlbumSelection
            albums={albums}
            finished={(album) => {
              setSelectedAlbumId(album.albumId ?? 0);
              clickUpload()
            }}
          />
        )}
        {!!selectedAlbumId && finishAnimation && (
          <div className={'upload-finished'}>
            <Lottie
              className={'upload-finished-animation'}
              animationData={heartSuccess}
              loop={false}
            />
            <div className={'upload-finished-text'}>
              <div className={'upload-finished-text-2'}>
                Die Bilder wurden erfolgreich hochgeladen. Die Veröffentlung
                kann ein paar Minuten dauern.
              </div>
              <div className={'upload-finished-text-2'}>😊💗</div>
              <Button
                variant="contained"
                size={'small'}
                onClick={() => {
                  if (onFinish) {
                    onFinish();
                  }
                }}
              >
                Schließen
              </Button>
            </div>
          </div>
        )}
        {!!selectedAlbumId && !finishAnimation && (
          <>
            {publishingInProgress && uploadProgress && (
              <div className={'uploader-container'}>
                <div className={'uploader-description'}>
                  {uploadProgress.percentage <= 100 && (
                    <CircularProgress
                      variant="determinate"
                      value={uploadProgress.percentage}
                    />
                  )}
                  <span className={'uploader-percentage'}>{`${Math.round(
                    uploadProgress.percentage
                  )}%`}</span>
                  <span className={'uploader-text'}>Hochgeladen</span>
                </div>
              </div>
            )}

            {!publishingInProgress && (
              <div
                className={'uploader-container'}
                {...dragProps}
                id={'image-gallery'}
              >
                <div className={'uploader-description'}>
                  <UploadSvg /> <br />
                  <span className={'uploader-text'}>
                    Ziehe Fotos und Videos hierher
                  </span>
                  <Button variant="contained" size={'small'}>
                    Fotos / Videos auswählen
                  </Button>
                </div>
                <input
                  className={'input-upload'}
                  id={'my-uploader-button'}
                  type="file"
                  multiple={true}
                  onChange={async (e) => {
                    const target = e.target;
                    const chosenFiles = getFilesFromEvent(e) as File[];
                    handleFiles(chosenFiles).then(() => {});
                    //@ts-ignore
                    target.value = null;
                  }}
                />
              </div>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
