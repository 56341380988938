import React from 'react';
import { AlbumDto } from '@backend/models';

export interface AlbumSelectionProps {
  albums: AlbumDto[];
  finished: (prop: AlbumDto) => void;
}

export const AlbumSelection = (props: AlbumSelectionProps) => {
  return (
    <div className={'album-overview'}>
      {props.albums.map((album) => {
        if(album.albumId==2){
          return  null;
        }
        return (
          <div
            key={'h' + album.albumId}
            onClick={() => {
              props.finished(album);
            }}
          >
            <div>
              <img src={album.avatarUrl} />
              <div className={'album-title'}>{album.title}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
