import React from 'react';
import { ListItem, ListItemAvatar, Stack } from '@mui/material';
import { CommentDto } from '@backend/models';
import moment from 'moment';
import { MyAvatar } from '../avatar/my-avatar';
import { LikeButton } from '../like-button/like-button';
import { Guid } from '@customTypes';

interface CommentListItemProps {
  comment: CommentDto;
  setReplyComment: (comment: CommentDto) => void;
  isReplyComment: boolean;
  doLike: (commentHandle: Guid, liked: boolean) => void;
  delete: (comment: CommentDto) => void;
}

export const CommentListItem = (props: CommentListItemProps) => {
  const comment = props.comment;

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <MyAvatar user={comment.user} />
        </ListItemAvatar>
        <Stack direction={'column'} sx={{ flex: '1' }}>
          <div className={'MuiTypography-root MuiTypography-body2'}>
            {comment.user?.name} •{' '}
            {moment(comment.date.substring(0, 19) + 'Z').fromNow()}
          </div>
          <Stack direction={'column'}>
            <div className={'comment-txt'}>{comment.text}</div>
            {!props.isReplyComment && (
              <Stack direction={'row'} className={'like-stack'}>
                <div>
                  <LikeButton
                    likes={comment.likes}
                    liked={comment.selfLiked}
                    setLiked={(val) => {
                      props.doLike(comment.handle, val);
                    }}
                  />
                </div>
                <div
                  onClick={() => {
                    props.setReplyComment(comment);
                  }}
                >
                  Antworten
                </div>
                {comment.editable && (
                  <div
                    onClick={() => {
                      props.delete(comment);
                    }}
                  >
                    ᛫ Löschen
                  </div>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </ListItem>
      {!!comment.comments &&
        comment.comments.map((sc) => (
          <ListItem
            alignItems="flex-start"
            className={'response-comment'}
            key={sc.handle}
          >
            <ListItemAvatar>
              <MyAvatar user={sc.user} />
            </ListItemAvatar>
            <Stack direction={'column'} sx={{ flex: '1' }}>
              <div className={'MuiTypography-root MuiTypography-body2'}>
                {sc.user?.name} •{' '}
                {moment(sc.date.substring(0, 19) + 'Z').fromNow()}
              </div>
              <Stack direction={'column'} sx={{ width: '100%' }}>
                <div className={'comment-txt'}>{sc.text}</div>
                {!props.isReplyComment && (
                  <Stack direction={'row'} className={'like-stack'}>
                    <div>
                      <LikeButton
                        likes={sc.likes}
                        liked={sc.selfLiked}
                        setLiked={(val) => {
                          props.doLike(sc.handle, val);
                        }}
                      />
                    </div>
                    {comment.editable && (
                      <div
                        onClick={() => {
                          props.delete(comment);
                        }}
                      >
                        ᛫ Löschen
                      </div>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </ListItem>
        ))}
    </>
  );
};
