import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import UploadSvg from '../../svg/upload.svg';
import { MyCropper } from './my-cropper';
import { CropResult } from './cropper.models';
import { getFilesFromEvent } from '../../utils/uploader.utils';
import * as PhoneInput from 'react-phone-input-2';
import { clickUpload } from '../../clickutils';

export interface CropModalProps {
  startCropImageId?: number;
  startImageFile?: File;
  title?: string;
  subTitle?: string;
  inputText?: string;
  inputTextLabel?: string;
  showPhoneNumber?: boolean;
  phoneNumber?: string;
  open?: boolean;
  aspectRatio?: number;
  cropShape?: 'rect' | 'round';
  onCancel?: () => void;
  onFinish?: (result: CropResult) => Promise<void>;
  newMaxLength?: number;
  createVariants?: boolean;
  autoUpload?: boolean;
}

interface InputError {
  title?: string;
  phone?: string;
}

export const CropModal = (props: CropModalProps) => {
  const [img, setImg] = useState<File | undefined>(props.startImageFile);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    props.phoneNumber || ''
  );
  const [error, setError] = useState<InputError>();
  const [step, setStep] = useState(
    props.startImageFile ? Step.Crop : Step.FileUpload
  );
  const titleInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setPhoneNumber(props.phoneNumber || '');
  }, [props.phoneNumber]);

  useEffect(() => {
    setImg(props.startImageFile);
    setStep(
      props.startImageFile || props.startCropImageId
        ? Step.Crop
        : Step.FileUpload
    );
  }, [props.startImageFile, props.startCropImageId]);

  const handleClose = () => {
    props?.onCancel?.();
  };

  useEffect(() => {
    if (!titleInputRef.current) {
      return;
    }
    titleInputRef.current.value = props.inputText || '';
  }, [props.inputText, titleInputRef]);

  useEffect(() => {
    if (!props.autoUpload) {
      return;
    }
    clickUpload()
  }, [props.autoUpload]);

  return (
    <Dialog
      onClose={handleClose}
      open={!!props?.open}
      className={'my-upload-dialog'}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          handleClose();
        }}
        className={'close'}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{props.title || 'Foto'}</DialogTitle>

      <DialogContent>
        <div className={'cropper-container'}>
          {props.inputTextLabel && (
            <div className={'cropper-controls-1'}>
              <label>{props.inputTextLabel}</label>
              <input
                className="text-input"
                type="text"
                placeholder={props.inputTextLabel || 'Foto'}
                defaultValue={props.inputText}
                ref={titleInputRef}
              />
            </div>
          )}
          {props.showPhoneNumber && (
            <div className={'cropper-controls-1'}>
              <label>
                Ohne Angabe einer Telefonummer verlierst du bei Logout den
                Zugriff auf deine Fotos und Kommentare.
              </label>
              <PhoneInput.default
                country={'at'}
                value={phoneNumber || ''}
                onlyCountries={['at', 'rs', 'de']}
                placeholder={'+43 664 123 456 78'}
                areaCodes={[]}
                onChange={(e) => {
                  setPhoneNumber(e);
                }}
              />
            </div>
          )}
          {step === Step.FileUpload && (
            <div className={'uploader-container'}>
              <div className={'uploader-description'}>
                <UploadSvg /> <br />
                <span className={'uploader-text'}>
                  {props.subTitle || 'Foto'}
                </span>
                <Button variant="contained" size={'small'}>
                  Foto auswählen
                </Button>
              </div>
              <input
                className={'input-upload'}
                id={'my-uploader-button'}
                type="file"
                multiple={false}
                onChange={async (e) => {
                  const chosenFiles = getFilesFromEvent(e) as File[];
                  const file = chosenFiles[0];
                  if (!file.type.includes('image')) {
                    const name = file.name.split('.');
                    const extension = name[name.length - 1];
                    window.alert(
                      'Bitte wählen Sie ein anderes Foto.\n(Eine Datei mit Endung .' +
                        extension +
                        ' wird aktuell nicht unterstützt.)'
                    );
                    return;
                  }
                  setImg(file);
                  setStep(Step.Crop);
                }}
              />
            </div>
          )}
          {step === Step.Crop && (
            <MyCropper
              restrictPosition={true}
              cropShape={props.cropShape || 'round'}
              showGrid={true}
              aspect={props.aspectRatio || 1}
              startCropImageId={props.startCropImageId}
              startImageFile={img}
              onCancel={props?.onCancel}
              newMaxLength={props?.newMaxLength}
              createVariants={props?.createVariants}
              onFinish={async (val) => {
                const v = {
                  ...val,
                  title: titleInputRef.current?.value || '',
                  phoneNumber: phoneNumber,
                };
                if (props.onFinish) {
                  await props.onFinish(v);
                }
              }}
              title={props?.title}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

enum Step {
  FileUpload = 20,
  Crop = 30,
}
