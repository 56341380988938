import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, IconButton, List, Stack } from '@mui/material';
import { CommentDto, NewCommentRequestDto } from '@backend/models';
import { Guid } from '@customTypes';
import { useApi } from '@backend/api';
import CloseIcon from '@mui/icons-material/Close';
import EmojiPicker, { SuggestionMode } from 'emoji-picker-react';
import Textarea from 'react-expanding-textarea';
import { CommentListItem } from './comment-list';
import { MyAvatar } from '../avatar/my-avatar';
import { useUserContext } from '@event-images/shared';
import './comment-drawer.scss';
import { IoAdd } from 'react-icons/io5';
import { IoRemove } from 'react-icons/io5';
import { IoChevronUp } from 'react-icons/io5';

interface CommentDrawerProps {
  postHandle: Guid | undefined;
  closeComments: () => void;
  maximizedComments: boolean;
  setMinimizeComments: () => void;
  setMaximizeComments: () => void;
}

let onceOpened = false;
export const CommentDrawer = (props: CommentDrawerProps) => {
  const api = useApi();
  const { user } = useUserContext();

  const [useEmojiPicker, setUseEmojiPicker] = useState(false);
  const [comments, setComments] = useState<CommentDto[]>([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [replyComment, _setReplyComment] = useState<CommentDto>();
  const inputRef = useRef<HTMLInputElement>(null);

  const loadComments = useCallback(
    (postHandle: Guid) => {
      api.postApiService.comments_GET(postHandle).then((c) => {
        const c2 = c.result || [];
        c2.sort((a, b) => (a.date < b.date ? 1 : -1));
        setComments(c2);
        let t = 0;
        for (const cm of c2) {
          t += (cm.comments?.length || 0) + 1;
        }
        setCommentsCount(t);
      });
    },
    [api.postApiService]
  );

  const setReplyComment = useCallback(
    (val: CommentDto | undefined) => {
      _setReplyComment(val);
      if (val && inputRef.current) {
        inputRef.current.focus();
      }
    },
    [inputRef]
  );

  const doDelete = useCallback(
    (comment: CommentDto) => {
      api.postApiService
        .deleteItems_POST({
          comments: [comment.handle],
        })
        .then((r) => {
          if (props.postHandle) {
            loadComments(props.postHandle);
          }
        });
    },
    [api.postApiService, props.postHandle, loadComments]
  );
  const doLike = useCallback(
    (commentHandle: Guid, liked: boolean) => {
      api.postApiService
        .like_POST({
          commentHandle: commentHandle,
          val: liked ? 1 : 0,
        })
        .then((r) => {});
    },
    [api.postApiService]
  );

  useEffect(() => {
    setUseEmojiPicker(false);
    setComments([]);
    if (!props.postHandle) {
      setComments([]);
      return;
    }
    onceOpened = true;
    loadComments(props.postHandle);
  }, [loadComments, props.postHandle]);

  const onClickHandler = (item: string) => {
    if (inputRef.current) {
      inputRef.current.value = inputRef.current.value + item;
    }
  };

  const sendComment = () => {
    const tt: NewCommentRequestDto = {
      postHandle: props.postHandle,
      text: inputRef.current?.value ?? '',
    };
    if (replyComment) {
      tt.commentHandle = replyComment.handle;
    }
    api.postApiService.comment_POST(tt).then((c) => {
      setUseEmojiPicker(false);
      setReplyComment(undefined);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      if (props.postHandle) {
        loadComments(props.postHandle);
      }
    });
  };
  return (
    <Stack direction={'column'} className={'comment-box'}>
      <Stack className={'puller-container'} direction={'row'}>
        <div className={'comment-title'}>
          <strong>Kommentare</strong> {commentsCount}
        </div>
        <div className={'comment-close'}>
          {!props.maximizedComments && (
            <IconButton
              sx={{ color: '#000000' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.setMaximizeComments();
              }}
            >
              <IoChevronUp style={{ stroke: '#ffdc73' }} />
            </IconButton>
          )}

          <IconButton
            sx={{ color: '#000000' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.setMinimizeComments();
              props.closeComments();
            }}
          >
            <CloseIcon style={{ fill: '#ffdc73' }} />
          </IconButton>
        </div>
      </Stack>

      <div className={'comment-comments'}>
        <List>
          {comments.map((comment) => (
            <CommentListItem
              key={comment.handle}
              comment={comment}
              setReplyComment={setReplyComment}
              isReplyComment={false}
              doLike={doLike}
              delete={doDelete}
            />
          ))}
        </List>
      </div>

      {!!replyComment && (
        <div className={'reply-comment'}>
          <div className={'reply-comment-title'}>Antwort auf:</div>
          <CommentListItem
            comment={replyComment}
            setReplyComment={() => {}}
            isReplyComment={true}
            doLike={doLike}
            delete={doDelete}
          />
          <div className={'reply-close'}>
            <IconButton
              sx={{ color: '#000000' }}
              onClick={(e) => {
                setReplyComment(undefined);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      )}

      <Stack
        className={
          'comment-input--container ' +
          (useEmojiPicker ? 'comment-input--container--focused' : '')
        }
        flexDirection={'row'}
      >
        <div className={'comment-input--avatar'}>
          <MyAvatar user={user?.user} />
        </div>

        <div className={'comment-input--input '}>
          <Textarea ref={inputRef} placeholder={'Kommentieren...'} />
        </div>
        <div className={'comment-input--send'}>
          <Button
            color="primary"
            onClick={(e) => {
              sendComment();
            }}
            sx={{
              width: '30px',
              height: '30px',
            }}
          >
            Posten
          </Button>
        </div>
      </Stack>
      {!!props.postHandle && !useEmojiPicker && (
        <div className={'comment-input--emoji'}>
          <ul>
            <li>
              <a onClick={() => onClickHandler('😍')}>
                <img
                  src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f60d.png"
                  alt="heart eyes"
                  loading="eager"
                />
              </a>
            </li>
            <li>
              <a onClick={() => onClickHandler('❤️')}>
                <img
                  src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/2764-fe0f.png"
                  alt="heart"
                  loading="eager"
                />
              </a>
            </li>
            <li>
              <a onClick={() => onClickHandler('💕')}>
                <img
                  src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f495.png"
                  alt="two hearts"
                  loading="eager"
                />
              </a>
            </li>
            <li>
              <a onClick={() => onClickHandler('😘')}>
                <img
                  src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f618.png"
                  alt="kissing heart"
                  loading="eager"
                />
              </a>
            </li>
            <li>
              <a onClick={() => onClickHandler('👍')}>
                <img
                  src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f44d.png"
                  alt="+1"
                  loading="eager"
                />
              </a>
            </li>
            <li>
              <a onClick={() => onClickHandler('😃')}>
                <img
                  src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f603.png"
                  alt="smiley"
                  loading="eager"
                />
              </a>
            </li>
            <li>
              <a onClick={() => onClickHandler('🤣')}>
                <img
                  src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f923.png"
                  alt="rolling on the floor laughing"
                  loading="eager"
                />
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setUseEmojiPicker(true);
                }}
              >
                <IoAdd />
              </a>
            </li>
          </ul>
        </div>
      )}
      {!!props.postHandle && useEmojiPicker && (
        <div className={' comment-input--emoji1 comment-input--emoji2'}>
          <EmojiPicker
            height={20}
            reactions={[
              '1f60d',
              '2764-fe0f',
              '1f495',
              '1f618',
              '1f44d',
              '1f603',
              '1f923',
            ]}
            reactionsDefaultOpen={false}
            previewConfig={{
              showPreview: false,
            }}
            skinTonesDisabled={true}
            suggestedEmojisMode={SuggestionMode.FREQUENT}
            allowExpandReactions={false}
            onEmojiClick={(e) => {
              if (inputRef.current) {
                inputRef.current.value = inputRef.current.value + e.emoji;
              }
            }}
            onReactionClick={(e) => {
              if (inputRef.current) {
                inputRef.current.value = inputRef.current.value + e.emoji;
              }
            }}
          />
          <IconButton
            onClick={() => {
              setUseEmojiPicker(!useEmojiPicker);
            }}
          >
            <IoRemove />
          </IconButton>
        </div>
      )}
    </Stack>
  );
};
