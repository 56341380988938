import { useCallback, useEffect, useMemo, useState } from 'react';
import { Context, PostWrapper } from './scroller.models';
import { useEventContext } from '@event-images/shared';
import { useApi } from '@backend/api';
// @ts-ignore
import { shareOnMobile } from 'react-mobile-share';
import { loadChallenges } from '../../utils/challengeLoader';
import { ChallengeDetailDto } from '@backend/models';

export const usePostContext = (
  setRefreshScroller: React.Dispatch<React.SetStateAction<number>>
) => {
  const { setCommentPostHandle, setConfirmModal } = useEventContext();
  const [challenges, setChallenges] = useState<ChallengeDetailDto[]>();

  const api = useApi();
  useEffect(() => {
    const asyncFn = async () => {
      const c = await loadChallenges(api);
      setChallenges(c);
    };
    asyncFn();
  }, [api]);

  const share = useCallback((post: PostWrapper) => {
    const shareIt = () => {
      shareOnMobile({
        text: 'Hochzeit' + post.post.date?.format('D.M HH:mm'),
        url:
          'https://gold-berger.at/album/' +
          post.post.data.albumId +
          '/scroller/' +
          post.post.data.handle,
        title: 'Christian & Reinhard ',

        //images: [imgBase64],
      });
    };
    shareIt();
  }, []);

  const comment = useCallback(
    (post: PostWrapper) => {
      setCommentPostHandle(post.post.data.handle);
    },
    [setCommentPostHandle]
  );

  const del = useCallback(
    (post: PostWrapper) => {
      setConfirmModal({
        title: 'Löschen',
        text: 'Möchtest du dieses Bild löschen?',
        okText: 'Löschen',
        onCancel: () => {
          setConfirmModal(undefined);
        },
        onFinish: async (val) => {
          await api.postApiService.deleteItems_POST({
            posts: [post.post.data.handle],
          });
          setRefreshScroller((prev) => prev + 1);

          setConfirmModal({
            title: 'Löschen',
            text: 'Bild gelöscht.',
            okText: 'Ok',
            onCancel: () => {
              setConfirmModal(undefined);
            },
            onFinish: async (val) => {
              setConfirmModal(undefined);
            },
            open: true,
          });
        },
        open: true,
      });
    },
    [setRefreshScroller, setConfirmModal, api.postApiService]
  );

  const like = useCallback(
    (post: PostWrapper, liked: boolean) => {
      api.postApiService
        .like_POST({
          postHandle: post.post.data.handle,
          val: liked ? 1 : 0,
        })
        .then((r) => {});
    },
    [api.postApiService]
  );

  const context: Context = useMemo(
    () => ({
      share: share,
      comment: comment,
      like: like,
      delete: del,
      challenges: challenges || [],
    }),
    [like, comment, share, del, challenges]
  );

  return context;
};
