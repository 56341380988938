import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserModel } from '../models/user.model';
import { useApi } from '@backend/api';
import { RegisterResultDto } from '@backend/models';
import { requestPermission } from '@event-images/messaging';

interface UserContextValue {
  doLogin: (
    username: string,
    pin: string
  ) => Promise<RegisterResultDto | undefined>;
  doLogout: () => Promise<void>;
  loading: boolean;
  isLoggedIn: boolean;
  user: UserModel | undefined;
  setUser: Dispatch<SetStateAction<UserModel | undefined>>;
  autoLogin: boolean;
}

export const UserContext = createContext<UserContextValue>({} as any);

export const useUserContext = (): UserContextValue => useContext(UserContext);

export const UserProvider: React.FC<PropsWithChildren<{}>> = (props) => {
  const [user, setUser] = useState<UserModel>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [autoLogin, setAutoLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    api.userApiService
      .getSelf_POST({
        path: window.location.pathname,
      })
      .then((self) => {
        if (self.result?.loggedIn && self.result.user) {
          setIsLoggedIn(true);
          requestPermission(api).then(() => {});
          setUser({
            user: self.result.user,
            avatarId: self.result.avatarId,
            defaultAlbumId: self.result.defaultAlbumId,
          });
        } else {
          setIsLoggedIn(false);
          if (self.result?.autoLogIn) {
            setAutoLogin(true);
          }
        }

        setLoading(false);
      });
  }, [api]);

  const doLogin = useCallback(
    async (
      username: string,
      pin: string
    ): Promise<RegisterResultDto | undefined> => {
      const result = await api.authenticationApiService.register_POST({
        userName: username,
        pin: pin,
        telephone: '',
        path: window.location.pathname,
      });
      if (result?.result?.success && result.result?.self?.user) {
        setUser({
          user: result.result.self.user,
          avatarId: result.result.self.avatarId,
          defaultAlbumId: result.result.self.defaultAlbumId,
        });
        requestPermission(api).then(() => {});
        setIsLoggedIn(true);
      }
      return result?.result;
    },
    [api]
  );

  const doLogout = useCallback(async () => {
    setIsLoggedIn(false);
  }, []);

  return (
    <UserContext.Provider
      value={{
        doLogin: doLogin,
        doLogout: doLogout,
        loading: loading,
        isLoggedIn: isLoggedIn,
        user: user,
        setUser: setUser,
        autoLogin: autoLogin,
      }}
    >
      {!loading && props.children}
    </UserContext.Provider>
  );
};
