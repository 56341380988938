/* eslint-disable */
/**
 * v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {Guid, StringDate} from '@customTypes'
import url from 'url'
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@img/http-client";

import { ReAuthStep1 } from '../model/reAuthStep1';
import { ReAuthStep1Response } from '../model/reAuthStep1Response';
import { ReAuthStep2 } from '../model/reAuthStep2';
import { RegisterDto } from '../model/registerDto';
import { RegisterResultDto } from '../model/registerResultDto';



export class AuthenticationApiService {

    constructor(protected httpClient: HttpClient) {

    }


	/**
	 * 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // AuthenticationController.Logout  (EventImages.Server.Controllers)
     // authenticationLogoutPost
     public logout_POST(options: any = {}): Promise<ResponseWrap<Response>> {
		const _path = `/authentication/logout`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetchWithoutResponse('Authentication', 'authenticationLogoutPost', httpOptions);
	}


	/**
	 * 
	 * @param {ReAuthStep1} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // AuthenticationController.ReAuthStep1  (EventImages.Server.Controllers)
     // authenticationReauthStep1Post
     public reAuthStep1_POST(body?: ReAuthStep1, options: any = {}): Promise<ResponseWrap<ReAuthStep1Response>> {
		const _path = `/authentication/reauth/step1`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"ReAuthStep1" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<ReAuthStep1Response>('Authentication', 'authenticationReauthStep1Post', httpOptions);
	}


	/**
	 * 
	 * @param {ReAuthStep2} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // AuthenticationController.ReAuthStep2  (EventImages.Server.Controllers)
     // authenticationReauthStep2Post
     public reAuthStep2_POST(body?: ReAuthStep2, options: any = {}): Promise<ResponseWrap<RegisterResultDto>> {
		const _path = `/authentication/reauth/step2`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"ReAuthStep2" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<RegisterResultDto>('Authentication', 'authenticationReauthStep2Post', httpOptions);
	}


	/**
	 * 
	 * @param {RegisterDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // AuthenticationController.Register  (EventImages.Server.Controllers)
     // authenticationRegisterPost
     public register_POST(body?: RegisterDto, options: any = {}): Promise<ResponseWrap<RegisterResultDto>> {
		const _path = `/authentication/register`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"RegisterDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<RegisterResultDto>('Authentication', 'authenticationRegisterPost', httpOptions);
	}


}
