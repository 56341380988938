import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import React from 'react';
import { CssBaseline } from '@mui/material';

import './app.module.scss';

import {
  LoginView,
  Album,
  ScrollerView,
  Challenge,
  SingleScroller,
  MyTimeLine,
  LoginPhoneView,
  Galleries,
} from '@event-images/ui';
import { useUserContext } from '@event-images/shared';
import { MobileSkeleton } from '@event-images/ui';
import { QRCode } from '../../../../libs/ui/src/views/challenge/QrCode';

export const Skeleton: React.FC = () => {
  const { isLoggedIn, loading } = useUserContext();
  const isMobile = window.innerWidth < 768;

  const router = isLoggedIn
    ? createBrowserRouter(
        [
          {
            path: '/',
            children: [
              {
                path: '/',
                element: <MyTimeLine />,
              },
              {
                path: 'album/:albumId',
                element: <Album />,
              },
              {
                path: 'my-images',
                element: <Album myImages={true} />,
              },
              {
                path: '/my-images/scroller',
                element: <ScrollerView myImages={true} />,
              },
              {
                path: 'galleries',
                element: <Galleries />,
              },
              {
                path: 'challenges',
                element: <Challenge />,
              },
              {
                path: 'challenges/:challengeId',
                element: <Challenge />,
              },
              {
                path: 'scroller/challenges/:challengeId',
                element: <ScrollerView />,
              },
              {
                path: '/album/:albumId/scroller',
                element: <ScrollerView />,
              },
              {
                path: '/album/:albumId/scroller/:postHandle',
                element: <ScrollerView />,
              },
               {
                path: '/qrcode',
                element: <QRCode  />,
              },
              {
                path: '/logout',
                element: <LoginView logout={true} />,
              },
              {
                path: '*',
                element: <Navigate to="/" replace />,
              },
            ],
            element: <MobileSkeleton login={false} />,
          },
        ],
        {
          basename: import.meta.env.BASE_URL,
        }
      )
    : createBrowserRouter(
        [
          {
            path: '/',
            children: [
              {
                path: '',
                element: <LoginView />,
              },
              {
                path: '/album/:albumId/scroller/:postHandle',
                element: <SingleScroller />,
              },
              {
                path: ':spath/phone',
                element: <LoginPhoneView />,
              },
              {
                path: 'phone',
                element: <LoginPhoneView />,
              },
              {
                path: '*',
                element: <LoginView />,
              },
            ],
            element: <MobileSkeleton login={true} />,
          },
        ],
        {
          basename: import.meta.env.BASE_URL,
        }
      );
  if (loading) {
    return null;
  }
  return (
    <>
      <CssBaseline />
      <div className={isLoggedIn ? 'loggedin' : 'loggedout'}>
        <RouterProvider router={router} />
      </div>
    </>
  );
};
