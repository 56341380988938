import { Box, Button, Stack, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useApi } from '@backend/api';
import './login.scss';
import { useUserContext } from '@event-images/shared';
import Lottie from 'lottie-react';
import flowersAnimation from '../../lottie/flowers.json';
import ringsAnimation from '../../lottie/rings.json';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

export interface LoginViewProps {
  logout?: boolean;
}

export const LoginView = (props: LoginViewProps) => {
  let currentLogout = false;
  if (window.location.search.includes('successfullyLoggedOut')) {
    currentLogout = true;
  }

  const api = useApi();
  const usedNameRef = useRef<HTMLInputElement>();
  const pinRef = useRef<HTMLInputElement>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { doLogin, autoLogin } = useUserContext();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (!props.logout) {
      return;
    }
    api.authenticationApiService.logout_POST().then(() => {
      document.location.href = '/?successfullyLoggedOut';
    });
  }, [props.logout, api.authenticationApiService]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    const result = await doLogin(
      usedNameRef.current?.value || '',
      pinRef.current?.value || ''
    );

    if (result?.success) {
      setError(undefined);
      document.location.href = '/';
    } else {
      setError(result?.error?.[0]?.description);
      setLoading(false);
    }
  };
  return (
    <>
      <div className={'skeleton--center-image-container'}>
        <Lottie
          className={'login-logo'}
          animationData={ringsAnimation}
          loop={true}
        />
        <h4>Gold-Berger</h4>
      </div>

      <Stack className={'login-stack'}>
        {!autoLogin && (
          <Box className={'login-form'}>
            <div className={'sentCode2'}>Willkommen</div>
            <div className={'sentCode2'}>
              Bitte verwende den QR-Code für das Login.
            </div>
          </Box>
        )}

        {autoLogin && (
          <Box
            component="form"
            noValidate
            className={'login-form'}
            onSubmit={handleSubmit}
          >
            {currentLogout && (
              <div className={'sentCode'}>Erfolgreich ausgeloggt.</div>
            )}
            <TextField
              inputRef={usedNameRef}
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Dein Name"
              id="usedname"
              name="usedname"
              autoFocus
            />
            {!autoLogin && (
              <TextField
                inputRef={pinRef}
                variant={'standard'}
                margin="normal"
                required
                fullWidth
                id="pin"
                label="Pin"
                name="pin"
                autoFocus
              />
            )}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              className={'login-button'}
              sx={{ mt: 3, mb: 2 }}
            >
              Starten
            </LoadingButton>
            <div className={'sentCode2'}>
              <Link
                to={(window.location.href + '/phone').replace(/\/\//g, '/')}
              >
                Login mit Telefonummer
              </Link>
            </div>
            <div className={'sentCode'}>{error}</div>
          </Box>
        )}
        <Lottie
          className={'flowers'}
          animationData={flowersAnimation}
          loop={true}
        />
      </Stack>
    </>
  );
};
