import React, { useCallback, useEffect, useRef } from 'react';
import { IconButton, Stack } from '@mui/material';

import { Context, PostWrapper } from './scroller.models';
import { EType } from '@backend/models';
import { LikeButton } from '../../components/like-button/like-button';
import moment from 'moment/moment';
import {
  IoChatboxEllipsesOutline,
  IoDownloadOutline,
  IoShareSocialOutline
} from 'react-icons/io5';
import { IoTrashOutline } from 'react-icons/io5';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';

interface InnerItemProps {
  index: number;
  postWrapper: PostWrapper;
  context: Context;
  noTitle?: boolean;
}

// Item contents are cached properly with React.memo
export const InnerItem = React.memo((props: InnerItemProps) => {
  const post = props.postWrapper.post.data;

  let title = '';
  if (post.challenge?.id && !props.noTitle) {
    const c = props.context.challenges.find((x) => x.id == post.challenge?.id);
    if (c) {
      title = c.text;
    }
  }

  const imgRef = useRef<HTMLImageElement>(null);

  const onUpdate = useCallback(({ x, y, scale }: any) => {
    const { current: img } = imgRef;

    if (img) {

      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty('transform', value);
    }
  }, []);

  return (
    <>
      {post.type === EType.Video && (
        <video
          data-src={post.images?.video}
          autoPlay={true}
          controls
          poster={post.images?.i640w}
        />
      )}
      {post.type === EType.Image && (
        <>
          <div className={'myDragger myDragger-1'}></div>

          <QuickPinchZoom onUpdate={onUpdate}  >
            <img ref={imgRef} src={post.images?.i640w} />

          </QuickPinchZoom>
          <div className={'myDragger myDragger-2'}></div>
        </>
      )}


      <Stack className={'scroller--buttons'} direction={'column'}>
        <LikeButton
          liked={post.selfLiked}
          likes={post.likes}
          asStack={true}
          stackDirection={'column'}
          setLiked={(val) => {
            props.context.like(props.postWrapper, val);
          }}
        />

        <IconButton
          sx={{ color: '#FFFFFF' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.context.comment(props.postWrapper);
          }}
        >
          <Stack>
            <IoChatboxEllipsesOutline />
            <div className={'counter'}>
              {props.postWrapper.post.data.commentsCount > 0
                ? props.postWrapper.post.data.commentsCount
                : ''}
            </div>
          </Stack>
        </IconButton>

        <IconButton
          sx={{ color: '#FFFFFF' }}
          onClick={() => props.context.share(props.postWrapper)}
        >
          <IoShareSocialOutline />
        </IconButton>
        <IconButton
          sx={{ color: '#FFFFFF' }}
          href={post.images?.video || post.images?.i1200w || ''}
          target={'_blank'}
        >
          <IoDownloadOutline />
        </IconButton>
        {post.editable && (
          <IconButton
            sx={{ color: '#FFFFFF' }}
            onClick={() => props.context.delete(props.postWrapper)}
          >
            <IoTrashOutline />
          </IconButton>
        )}
      </Stack>

      <div
        className={
          'scroller--footer ' +
          (post.type === EType.Video ? ' scroller--footer-video' : '')
        }
      >
        {!!title && <div className={'title'}>{title}</div>}
        <h5>
          von <strong>{post.userName}</strong>
        </h5>
        <h6>
          {moment(post.createdDate.substring(0, 19) + 'Z').format(
            'DD.MM.YYYY, HH:mm'
          )}
        </h6>
      </div>
    </>
  );
});

// The callback is executed often - don't inline complex components in here.
export const itemContent = (
  index: number,
  post: PostWrapper,
  context: Context
) => {
  return <InnerItem index={index} postWrapper={post} context={context} />;
};
