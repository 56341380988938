/**
 * v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {Guid, StringDate, ModelObject} from '@customTypes'

export type EType = 10 | 20 | 30 | 40;

export const EType = {
    Text: 10 as EType,
    Image: 20 as EType,
    Video: 30 as EType,
    PlaceHolder: 40 as EType
};
