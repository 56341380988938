import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import './my-cropper.scss';
import { CroppedImageDto, CropResultDto } from '@backend/models';
import { httpClient, useApi } from '@backend/api';
import LoadingButton from '@mui/lab/LoadingButton';
import { HttpOptions } from '@img/http-client';
import { IoCheckmark } from 'react-icons/io5';
import { Button } from '@mui/material';
import { CropResult } from './cropper.models';
import {
  dataUrlToFile,
  getFilesFromEvent,
  toBase64,
} from '../../utils/uploader.utils';
import moment from 'moment';

export interface MyCropperProps {
  startCropImageId?: number;
  startImageFile?: File;
  title: string | undefined;
  aspect: number; //4 / 3
  cropShape: 'rect' | 'round';
  showGrid: boolean;
  restrictPosition: boolean;
  onCancel: (() => void) | undefined;
  onFinish: ((result: CropResult) => Promise<void>) | undefined;
  newMaxLength: number | undefined;
  createVariants: boolean | undefined;
}

export const MyCropper = (props: MyCropperProps) => {
  const [aspect, setAspect] = useState(props.aspect);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [initialCrop, setInitialCrop] = useState<Area>();
  const [imageSrc, setImageSrc] = useState<string>();
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const croppedAreaRef = useRef<Area>();
  const api = useApi();

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    croppedAreaRef.current = croppedAreaPixels;
  };

  const setCroppedImage = useCallback(
    (croppedImage: CroppedImageDto | undefined) => {
      if (!croppedImage) {
        return;
      }
      const cd = croppedImage.parameters;
      setInitialCrop({
        width: cd?.width || 0,
        height: cd?.height || 0,
        x: cd?.x || 0,
        y: cd?.y || 0,
      });
      setZoom(cd?.zoom || 0);
      setRotation(cd?.rotation || 0);
      setImageSrc(croppedImage.url);
    },
    []
  );

  useEffect(() => {
    const asyncFn = async () => {
      if (props.startCropImageId && props.startCropImageId !== -1) {
        const albumData = await api.uploadApiService.getCroppedImageDto_POST(
          props.startCropImageId
        );
        setCroppedImage(albumData.result);
      } else {
        setCroppedImage(undefined);
      }
    };
    void asyncFn();
  }, [setCroppedImage, api.uploadApiService, props.startCropImageId]);

  const doSetImageSrc = useCallback(async (file: File) => {
    const fileBase64 = '' + (await toBase64(file));
    if (fileBase64.includes('/heic;')) {
      const formData = new FormData();
      formData.append('file', file);

      const httpOptions: HttpOptions = {
        url: `/uploads/jpeg`,
        autoHeader: true,
        options: {
          method: 'POST',
          body: formData,
        },
      };

      const result = await httpClient.fetch<string>(
        'Upload',
        'jpeg',
        httpOptions
      );
      const fileBase64 = 'data:image/jpeg;base64,' + result.result;
      setImageSrc(fileBase64);
      const _file = await dataUrlToFile(fileBase64, 'image.jpeg');
      setUploadedFile(_file);
    } else {
      setImageSrc(fileBase64);
      setUploadedFile(file);
    }
  }, []);
  useEffect(() => {
    const asyncFn = async () => {
      if (props.startCropImageId && props.startCropImageId !== -1) {
        // nothing
      } else if (props.startImageFile) {
        await doSetImageSrc(props.startImageFile);
      } else {
        setImageSrc(undefined);
      }
    };
    void asyncFn();
  }, [doSetImageSrc, props.startImageFile, props.startCropImageId]);

  const create = async (file: File): Promise<CropResultDto | undefined> => {
    if (!croppedAreaRef.current || !file) {
      return;
    }

    const formData = new FormData();

    for (const [k, v] of Object.entries(croppedAreaRef.current)) {
      formData.append(k, '' + Math.round(v));
    }
    const lastModified = moment(new Date(file.lastModified)).format(
      'YYYY-MM-DDTHH:mm:ss'
    );

    formData.append('cropShape', '' + props.cropShape);
    formData.append('aspectRatio', '' + props.aspect);
    formData.append('rotation', '' + Math.round(rotation));
    formData.append('file', file);
    formData.append('zoom', '' + Math.round(zoom * 100) / 100);
    formData.append('newMaxLength', '' + (props.newMaxLength ?? 240));
    formData.append('fileName', '' + file.name);
    formData.append('fileSize', '' + file.size);
    formData.append('lastModified', '' + lastModified);
    formData.append('fileType', file.type);
    formData.append('createVariants', '' + !!props.createVariants);

    const httpOptions: HttpOptions = {
      url: `/uploads/crop`,
      autoHeader: true,
      options: {
        method: 'POST',
        body: formData,
      },
    };

    const result = await httpClient.fetch<CropResultDto>(
      'Upload',
      'uploadsAvatarPost',
      httpOptions
    );

    return result.result;
  };

  const replace = async (
    originalCropImageId: number
  ): Promise<CropResultDto | undefined> => {
    if (!croppedAreaRef.current) {
      return;
    }

    const result = await api.uploadApiService.recropAvatar_POST({
      cropShape: props.cropShape,
      aspectRatio: props.aspect,
      width: croppedAreaRef.current.width,
      height: croppedAreaRef.current.height,
      x: croppedAreaRef.current.x,
      y: croppedAreaRef.current.y,
      rotation: rotation,
      zoom: zoom,
      originalCropImageId: originalCropImageId,
      newMaxLength: props.newMaxLength ?? 240,
      createVariants: !!props.createVariants,
    });

    return result.result;
  };

  const save = async () => {
    setLoading(true);
    let result: CropResultDto | undefined = undefined;
    if (uploadedFile) {
      result = await create(uploadedFile);
    } else if (props.startCropImageId) {
      result = await replace(props.startCropImageId);
    } else if (props.startImageFile) {
      result = await create(props.startImageFile);
    }
    if (result == undefined) {
      return;
    }

    if (props?.onFinish) {
      const res: CropResult = {
        cropImageId: result.uploadId,
        title: '',
        url: result.url,
      };
      await props.onFinish(res);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={'cropper-area'}>
        <Cropper
          image={imageSrc}
          rotation={rotation}
          crop={crop}
          initialCroppedAreaPixels={initialCrop}
          zoom={zoom}
          onMediaLoaded={(media) => {
            if (props.aspect === 1) {
              return;
            }
            if (media.height > media.width) {
              if (props.aspect > 1) {
                setAspect(1 / props.aspect);
              } else {
                setAspect(props.aspect);
              }
            } else {
              if (props.aspect < 1) {
                setAspect(1 / props.aspect);
              } else {
                setAspect(props.aspect);
              }
            }
          }}
          aspect={aspect}
          cropShape={props.cropShape}
          showGrid={props.showGrid}
          zoomSpeed={1}
          restrictPosition={props.restrictPosition}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={'cropper-controls'}>
        <label>Drehung</label>
        <input
          disabled={loading}
          className={'range'}
          type="range"
          min={0}
          max={360}
          list="rotation-detents"
          value={rotation}
          onChange={({ target: { value: rotation } }) =>
            setRotation(Number(rotation))
          }
        />
        <datalist id="rotation-detents">
          <option value="90" />
          <option value="180" />
          <option value="270" />
        </datalist>
      </div>
      <div className={'cropper-save'}>
        <div className={'upload-button-x'} style={{ textAlign: 'left' }}>
          <Button size="small" disabled={loading} variant="outlined">
            <span>Anderes Foto</span>
          </Button>
          {!loading && (
            <input
              className={'input-upload'}
              type="file"
              disabled={loading}
              multiple={false}
              onChange={async (e) => {
                const chosenFiles = getFilesFromEvent(e) as File[];
                const file = chosenFiles[0];
                if (!file.type.includes('image')) {
                  const name = file.name.split('.');
                  const extension = name[name.length - 1];
                  window.alert(
                    'Bitte wählen Sie ein anderes Foto.\n(Eine Datei mit Endung .' +
                      extension +
                      ' wird aktuell nicht unterstützt.)'
                  );
                  return;
                }
                await doSetImageSrc(file);
                setZoom(1);
                setRotation(0);
                setCrop({ x: 0, y: 0 });
              }}
            />
          )}
        </div>
        <div className={'upload-button-x'} style={{ textAlign: 'right' }}>
          <LoadingButton
            size="small"
            onClick={() => {
              void save();
            }}
            endIcon={<IoCheckmark />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Verwenden</span>
          </LoadingButton>
        </div>
      </div>
    </>
  );
};
