// eslint-disable-next-line no-undef

export interface HttpOptions {
  url: string;
  options: any;
  tryAfterJwt?: boolean;
  autoHeader?: boolean;
}

export interface ResponseWrap<TReturn> {
  ok: boolean;
  noConnection: boolean;
  result: TReturn | undefined;
  error?: string;
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError' as const;

  constructor(public field: string, msg?: string) {
    super(msg ?? '');
  }
}

/**
 *
 * @export
 */
export const COLLECTION_FORMATS: any = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

const defaultContentType = 'application/json';

export class HttpClient {
  constructor() {}

  public async fetch<TReturn>(
    serviceName: string,
    name: string,
    options: HttpOptions
  ): Promise<ResponseWrap<TReturn>> {
    const { apiUrl, fetchOptions } = this.getApiUrl(options);

    try {
      const response = await fetch(apiUrl + options.url, fetchOptions);
      const data = await response.json();
      return {
        ok: true,
        noConnection: true,
        result: data as TReturn,
      };
    } catch (err: any) {
      if (err.response?.status === 401) {
        (document.location as any).reload();
      }
      console.error(err.message);
    }

    return {
      ok: false,
      noConnection: true,
      result: undefined,
    };
  }

  public async fetchWithoutResponse(
    serviceName: string,
    name: string,
    options: HttpOptions
  ): Promise<ResponseWrap<Response>> {
    const { apiUrl, fetchOptions } = this.getApiUrl(options);

    try {
      const response = await fetch(apiUrl + options.url, fetchOptions);

      return {
        ok: true,
        noConnection: true,
        result: undefined,
      };
    } catch (err: any) {
      console.error(err.message);
    }

    return {
      ok: false,
      noConnection: true,
      result: undefined,
    };
  }

  private getApiUrl(options: HttpOptions) {
    // @ts-ignore
    const apiUrl = '/api';

    const fetchOptions = options.options as RequestInit;

    if (!fetchOptions.headers) {
      fetchOptions.headers = {};
    }
    const headers = fetchOptions.headers as any;
    if (!headers['Content-Type'] && !options.autoHeader) {
      headers['Content-Type'] = defaultContentType;
    }
    if (!headers.Accept) {
      headers.Accept = defaultContentType;
    }

    return { apiUrl, fetchOptions };
  }
}
