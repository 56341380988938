import { useApi } from '@backend/api';
import { Avatar, Badge, Button, Stack, Tab, Tabs } from '@mui/material';
import { MyContext } from './startpage.models';
import React from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import './album-header.scss';
import {
  CropResult,
  SelectionMode,
  SortMode,
  useEventContext, useUserContext
} from '@event-images/shared';
import {
  IoChatboxEllipsesOutline,
  IoHeartOutline,
  IoImageOutline,
} from 'react-icons/io5';

interface StartPageHeaderProps {
  context: MyContext;
}

export const AlbumHeader = (props: StartPageHeaderProps) => {
  const api = useApi();
  const { setCropModal, setRefreshMenu, setSelectionMode, selectionMode } =
    useEventContext();
  const albumDetail = props.context.albumDetail;
  const { user } = useUserContext();

  if (!albumDetail) {
    return;
  }
  const changeAvatar = () => {
    setCropModal({
      onCancel: () => {
        setCropModal(undefined);
      },
      title: 'Titel',
      subTitle: 'Titel Foto hochladen',
      inputTextLabel: 'Titel',
      inputText: albumDetail.title,
      onFinish: async (result: CropResult) => {
        const albumUpdated = await api.postApiService.updateAlbum_POST(
          albumDetail.albumId,
          {
            title: result.title,
            cropImageId: result.cropImageId,
          }
        );
        props.context.setAlbumDetail(albumUpdated.result);
        setCropModal(undefined);
        setRefreshMenu((prev) => prev + 1);
      },
      open: true,
      startImageFile: undefined,
      startCropImageId: albumDetail.avatarId,
    });
  };
  const isSelecting = selectionMode === SelectionMode.Selection;

  return (
    <Stack className={'start-row'} flexDirection={'column'}>
      <Stack className={'header'} flexDirection={'row'}>
        <div className={'avatar-container'}>
          {user?.user?.admin && (
            <div
              className={'avatar'}
              onClick={(e) => {
                changeAvatar();
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={<CameraAltIcon sx={{ color: '#182c4e' }} />}
              >
                <Avatar alt={albumDetail.title} src={albumDetail.avatarUrl} />
              </Badge>
            </div>
          )}
          {!user?.user?.admin && (
            <div className={'avatar'}>
              <Avatar alt={albumDetail.title} src={albumDetail.avatarUrl} />
            </div>
          )}
        </div>
        <Stack className={'header-items'} flexDirection={'column'}>
          <div className={'title'}>{albumDetail?.title}</div>
          <div className={'album-stat-container'}>
            <div className={'album-stat'}>
              {albumDetail.items > 0 && (
                <>
                  <IoImageOutline />
                  {albumDetail.items}
                </>
              )}
              {albumDetail.likes > 0 && (
                <>
                  <IoHeartOutline />
                  {albumDetail.likes}
                </>
              )}
              {albumDetail.comments > 0 && (
                <>
                  <IoChatboxEllipsesOutline />
                  {albumDetail.comments}
                </>
              )}
            </div>
          </div>
          <div className={'album-details-container'}>
            {albumDetail.editable && !isSelecting
              ? 'Du kannst neue Fotos hochladen'
              : ''}
            {isSelecting ? 'Bilder zum löschen markieren' : ''}
            {props.context.deleteButton && !isSelecting ? (
              <Button
                className="small-button"
                size={'small'}
                variant={'outlined'}
                onClick={() => {
                  setSelectionMode(SelectionMode.Selection);
                }}
              >
                Bilder Löschen
              </Button>
            ) : (
              ''
            )}
          </div>
        </Stack>
      </Stack>
      {props.context.isChallengeAlbum && <div style={{ height: '40px' }}></div>}
      {!props.context.isChallengeAlbum && (
        <Stack className={'subheader'} flexDirection={'row'}>
          <Tabs
            value={props.context.sortMode}
            onChange={(evt, value) => {
              switch (value) {
                case SortMode.Date:
                  props.context.setSortMode(SortMode.Date);
                  return;
                case SortMode.DateGrouped:
                  props.context.setSortMode(SortMode.DateGrouped);
                  return;
                case SortMode.Comments:
                  props.context.setSortMode(SortMode.Comments);
                  return;
                case SortMode.Likes:
                  props.context.setSortMode(SortMode.Likes);
                  return;
              }
            }}
            className={'sortmode'}
          >
            <Tab value={SortMode.Date} label={'Datum'} />
            <Tab value={SortMode.DateGrouped} label={'Datum Gruppiert'} />
            <Tab value={SortMode.Comments} label={'Kommentare'} />
            <Tab value={SortMode.Likes} label={'Likes'} />
          </Tabs>
        </Stack>
      )}
    </Stack>
  );
};
