import React, { useCallback, useState } from 'react';
import './mobile-skeleton.scss';
import './menu-button.scss';
import { Drawer, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Menu } from '../nav/menu';
import { BottomNav } from '../nav/bottom-nav';

import { CommentDrawer } from '../../components/comments/comment-drawer';
import { SelectionMode, useEventContext, useUserContext } from '@event-images/shared';
import { CurrentTitleItem } from '../nav/currentTitle';

interface MobileSkeletonProps {
  login?: boolean;
}

let debouncedMenuButtonTimer: number = 0;

export const MobileSkeleton = (props: MobileSkeletonProps) => {
  const [rightMenuOpen, _setRightMenuOpen] = React.useState(false);
  const [maximizedComments, setMaximizedComments] = useState(false);
  const { selectionMode, currentTitle, commentPostHandle, setCommentPostHandle } =
    useEventContext();

  const setRightMenuOpen = useCallback((val: boolean) => {
    const now = Date.now();
    const diff = (now - debouncedMenuButtonTimer) / 1000;
    if (diff < 0.5) {
      return;
    }
    debouncedMenuButtonTimer = now;
    _setRightMenuOpen(val);
  }, []);

  return (
    <>
      <Drawer
        anchor={'left'}
        elevation={1}
        open={rightMenuOpen}
        onClose={() => setRightMenuOpen(false)}
        //transitionDuration={0}
        classes={{
          root: 'left-menu-x'
        }}
        ModalProps={{
          keepMounted: true
        }}
        PaperProps={{
          sx: {
            width: 'calc(100% - 50px)',
            maxWidth: '400px',
            overflow: 'initial'
          }
        }}
      >
        {!props.login && (
          <Menu
            isOpen={rightMenuOpen}
            toggleOpen={() => setRightMenuOpen(!rightMenuOpen)}
            close={() => setRightMenuOpen(false)}
          />
        )}
      </Drawer>
      <Drawer
        anchor={'bottom'}
        open={!!commentPostHandle}
        onClose={() => setCommentPostHandle(undefined)}
        ModalProps={{
          keepMounted: true
        }}
        className={
          'comment-drawer ' +
          (maximizedComments ? 'comment-drawer--maximized' : '')
        }
      >
        <CommentDrawer
          postHandle={commentPostHandle}
          closeComments={() => setCommentPostHandle(undefined)}
          maximizedComments={maximizedComments}
          setMaximizeComments={() => setMaximizedComments(true)}
          setMinimizeComments={() => setMaximizedComments(false)}
        />
      </Drawer>
      <Stack
        direction={'column'}
        className={
          'scroller-container' +
          (props.login ? ' scroller-container-login' : '')
        }
      >
        {!props.login && (
          <div className={'scroller-top-navigation'}>
            <div className={'scroller-top-navigation-container'}>
              <div className={'nav-line'}>
                <CurrentTitleItem currentTitle={currentTitle} />

                {selectionMode !== SelectionMode.Selection && (
                  <div className={'menu-button'}>
                    <a
                      className={
                        'w-nav-control' + (rightMenuOpen ? ' active' : '')
                      }
                      aria-label="Menü"
                      onClick={() => {
                        setRightMenuOpen(!rightMenuOpen);
                      }}
                    >
                      <div className="w-nav-icon">
                        <div></div>
                      </div>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className={'scroller-entry'}>
          <Outlet />
        </div>
        {!props.login && <BottomNav />}
      </Stack>
    </>
  );
};
