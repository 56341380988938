/* eslint-disable */
/**
 * v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {Guid, StringDate} from '@customTypes'
import url from 'url'
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@img/http-client";

import { ChallengesResponseDto } from '../model/challengesResponseDto';
import { CreateChallengeDto } from '../model/createChallengeDto';
import { CreateChallengeResponseDto } from '../model/createChallengeResponseDto';
import { CreateChallengeResultDto } from '../model/createChallengeResultDto';
import { CreateChallengeResultResponseDto } from '../model/createChallengeResultResponseDto';



export class ChallengeApiService {

    constructor(protected httpClient: HttpClient) {

    }


	/**
	 * 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // ChallengeController.GetChallenges  (EventImages.Server.Controllers)
     // challengeAlbumsListPost
     public getChallenges_POST(options: any = {}): Promise<ResponseWrap<ChallengesResponseDto>> {
		const _path = `/challenge/albums/list`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<ChallengesResponseDto>('Challenge', 'challengeAlbumsListPost', httpOptions);
	}


	/**
	 * 
	 * @param {CreateChallengeDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // ChallengeController.CreateChallenge  (EventImages.Server.Controllers)
     // challengeCreatePost
     public createChallenge_POST(body?: CreateChallengeDto, options: any = {}): Promise<ResponseWrap<CreateChallengeResponseDto>> {
		const _path = `/challenge/create`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"CreateChallengeDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<CreateChallengeResponseDto>('Challenge', 'challengeCreatePost', httpOptions);
	}


	/**
	 * 
	 * @param {CreateChallengeResultDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // ChallengeController.CreateChallengResult  (EventImages.Server.Controllers)
     // challengeCreateResultPost
     public createChallengResult_POST(body?: CreateChallengeResultDto, options: any = {}): Promise<ResponseWrap<CreateChallengeResultResponseDto>> {
		const _path = `/challenge/createResult`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"CreateChallengeResultDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<CreateChallengeResultResponseDto>('Challenge', 'challengeCreateResultPost', httpOptions);
	}


}
