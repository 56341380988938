import React, { useCallback, useEffect, useRef, useState } from 'react';
import './menu.scss';
import { Avatar, Badge, Button, List, ListItem, Stack } from '@mui/material';
import { useApi } from '@backend/api';
import { AlbumDto } from '@backend/models';
import { LikeButton } from '../../components/like-button/like-button';
import {
  BottomMenu,
  CropResult,
  useEventContext,
  useUserContext,
} from '@event-images/shared';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Link } from 'react-router-dom';
import {
  IoCameraReverseOutline,
  IoChatboxEllipsesOutline,
  IoHeartOutline,
  IoImageOutline,
} from 'react-icons/io5';
import { IoTelescopeOutline } from 'react-icons/io5';

interface MenuProps {
  isOpen: boolean;
  toggleOpen: () => void;
  close: () => void;
}

export const Menu = (props: MenuProps) => {
  const api = useApi();
  const { user, setUser } = useUserContext();
  const {
    setCropModal,
    refreshMenu,
    setRefreshMenu,
    albums,
    setConfirmModal,
    setUploadAlbum,
    setRefreshAlbum,
  } = useEventContext();

  const like = useCallback(
    (albumId: number, liked: boolean) => {
      api.postApiService
        .like_POST({
          albumId: albumId,
          val: liked ? 1 : 0,
        })
        .then((r) => {});
    },
    [api.postApiService]
  );

  const changeAvatar = () => {
    setCropModal({
      onCancel: () => {
        setCropModal(undefined);
      },
      title: 'Profil bearbeiten',
      subTitle: 'Profil-Foto hochladen',
      inputTextLabel: 'Dein Name',
      inputText: user?.user?.name,
      showPhoneNumber: true,
      phoneNumber: user?.user?.phone,
      onFinish: async (result: CropResult) => {
        const updatedUser = await api.userApiService.updateUser_POST({
          usedName: result.title,
          cropImageId: result.cropImageId,
          phone: result.phoneNumber as any,
          phoneOnly: false,
        });
        setUser((prev) =>
          prev
            ? {
                ...prev,
                avatarId: result.cropImageId,
                user: {
                  ...prev.user,
                  avatar: result.url,
                  name: result.title,
                  phone: result.phoneNumber || '',
                },
              }
            : undefined
        );
        setCropModal(undefined);
      },
      open: true,
      startImageFile: undefined,
      startCropImageId: user?.avatarId,
    });
  };

  const logout = () => {
    setConfirmModal({
      title: 'Logout',
      text: 'Möchtest du dich Ausloggen?',
      okText: 'Ausloggen',
      onCancel: () => {
        setConfirmModal(undefined);
      },
      onFinish: async (val) => {
        if (val.phoneNumber) {
          await api.userApiService.updateUser_POST({
            usedName: '',
            cropImageId: 0,
            phoneOnly: true,
            phone: val.phoneNumber,
          });
        }
        api.authenticationApiService.logout_POST().then(() => {
          document.location.href = '/?successfullyLoggedOut';
        });
      },
      showPhoneNumber: !user?.user?.phone,
      phoneNumber: user?.user?.phone,
      open: true,
    });
  };

  const newAlbum = () => {
    setCropModal({
      onCancel: () => {
        setCropModal(undefined);
      },
      title: 'Titel',
      subTitle: 'Titelfoto hochladen',
      inputTextLabel: 'Titel',
      inputText: undefined,
      onFinish: async (result: CropResult) => {
        const albumUpdated = await api.postApiService.createAlbum_POST({
          title: result.title,
          cropImageId: result.cropImageId,
        });
        setRefreshMenu((prev) => prev + 1);
        setCropModal(undefined);
      },
      open: true,
      startImageFile: undefined,
      startCropImageId: undefined,
    });
  };

  const uploadPictures = () => {
    setUploadAlbum({
      albumId: 0,
      open: true,
      onClose: () => {
        setUploadAlbum(undefined);
      },
      onFinish: () => {
        setRefreshAlbum((prev) => prev + 1);
        setUploadAlbum(undefined);
      },
    });
  };
  return (
    <>
      <div className={'my-menu'}>
        <Stack className={'my-menu-user'} flexDirection={'row'}>
          <div
            className={'avatar'}
            onClick={(e) => {
              changeAvatar();
            }}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={<CameraAltIcon />}
            >
              <Avatar alt={user?.user?.name} src={user?.user?.avatar} />
            </Badge>
          </div>
          <div className={'username'}>{user?.user?.name}</div>
          <div className={'username'}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                logout();
              }}
            >
              <span>Logout</span>
            </Button>
          </div>
        </Stack>
        <div className={'alben'}>
          {user?.user?.admin && (
            <div className={'alben-neu'}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  newAlbum();
                }}
              >
                <span>Neues Album</span>
              </Button>
            </div>
          )}
        </div>
        <List
          className={'my-side-nav'}
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          dense={true}
        >
          {/*<Link
            to={'/timeline'}
            className={'outlook'}
            onClick={() => {
              props.toggleOpen();
            }}
          >
            <ListItem>
              <div className={'avatar avatar-none'}></div>
              <Stack className={'album-middle'}>
                <div className={'album-title'}>Startseite</div>
              </Stack>
            </ListItem>
          </Link>*/}
          <Link
            to={'/my-images'}
            className={'outlook'}
            onClick={() => {
              props.toggleOpen();
            }}
          >
            <ListItem>
              <div className={'avatar avatar2'}>
                {user?.user?.avatar ? (
                  <img src={user?.user?.avatar} />
                ) : (
                  <IoCameraReverseOutline style={{ stroke: '#ffdc73' }} />
                )}
              </div>
              <Stack className={'album-middle'}>
                <div className={'album-title'}>Eigene Bilder</div>
              </Stack>
            </ListItem>
          </Link>
          {(albums || []).map((album) => (
            <Link
              to={'/album/' + album.albumId}
              key={album.albumId}
              onClick={() => {
                props.toggleOpen();
              }}
            >
              <ListItem>
                <div className={'avatar'}>
                  <Avatar alt={album.title} src={album.avatarUrl} />
                </div>
                <Stack className={'album-middle'}>
                  <div className={'album-title'}>{album.title}</div>
                  <div className={'album-usename'}>
                    <div className={'album-usename2'}>
                      {album.items > 0 && (
                        <>
                          <IoImageOutline />
                          {album.items}
                        </>
                      )}
                      {album.likes > 0 && (
                        <>
                          <IoHeartOutline />
                          {album.likes}
                        </>
                      )}
                      {album.comments > 0 && (
                        <>
                          <IoChatboxEllipsesOutline />
                          {album.comments}
                        </>
                      )}
                    </div>
                  </div>
                </Stack>
                <LikeButton
                  liked={album.selfLiked}
                  likes={album.likes}
                  setLiked={(val) => {
                    like(album.albumId, val);
                  }}
                />
              </ListItem>
            </Link>
          ))}
          <Link
            to={'/qrcode'}
            className={'outlook'}
            onClick={() => {
              props.toggleOpen();
            }}
          >
            <ListItem>
              <div className={'avatar avatar-none'}></div>
              <Stack className={'album-middle'}>
                <div className={'album-title'}>QR Code</div>
              </Stack>
            </ListItem>
          </Link>
        </List>
        <div className={'logout-button'}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              uploadPictures();
            }}
          >
            <span>Bilder Upload</span>
          </Button>
        </div>
      </div>
    </>
  );
};
