import React from 'react';
import { Link } from 'react-router-dom';
import { IoChevronBackOutline } from 'react-icons/io5';
import {
  CurrentTitle,
  SelectionMode,
  useEventContext,
  useUserContext,
} from '@event-images/shared';
import { Button } from '@mui/material';
import { useApi } from '@backend/api';
import Lottie from 'lottie-react';
import ringsAnimation from '../../lottie/rings.json';
import { sleep } from '../../utils/postLoader';

interface CurrentTitleProps {
  currentTitle: CurrentTitle | undefined;
}

export const CurrentTitleItem = (props: CurrentTitleProps) => {
  const {
    selectionMode,
    selectedPostsState,
    setSelectionMode,

    setConfirmModal,
    setRefreshAlbum,
  } = useEventContext();
  const api = useApi();
  const { autoLogin, isLoggedIn } = useUserContext();

  if (!props.currentTitle) {
    return (
      <div className={'nav-header'}>
        <Lottie
          className={'nav-logo'}
          animationData={ringsAnimation}
          loop={false}
        />
        {autoLogin || isLoggedIn ? (
          <span className={'nav-title'}>Christian & Reinhard</span>
        ) : (
          <span className={'nav-title'}>Gold-Berger</span>
        )}
      </div>
    );
  }
  if (selectionMode === SelectionMode.Selection) {
    const cnt = Object.values(selectedPostsState).length;
    return (
      <div className={'scroller-top-navigation-title2'}>
        {cnt == 1 && (
          <span className={'nav-title3'}>{cnt} Bild ausgewählt</span>
        )}
        {cnt != 1 && (
          <span className={'nav-title3'}>{cnt} Bilder ausgewählt</span>
        )}
        <Button
          className="small-button"
          variant={'contained'}
          size={'small'}
          style={{marginRight:'6px'}}
          onClick={() => {
            setSelectionMode(SelectionMode.None);
          }}
        >
          Abbrechen
        </Button>
        <Button
          className="small-button"
          variant={'outlined'}
          size={'small'}
          style={{marginRight:'12px'}}
          onClick={() => {
            const handles = Object.values(selectedPostsState);

            setConfirmModal({
              title: 'Bilder Löschen',
              okText: 'Löschen',
              cancelText: 'Abbrechen',
              text:
                handles.length == 1
                  ? 'Bist Du sicher, dass Du dieses Bild löschen willst? '
                  : 'Bist Du sicher, dass Du diese ' +
                    handles.length +
                    ' Bilder löschen willst? ',
              onCancel: () => {
                setConfirmModal(undefined);
              },
              open: true,
              onFinish: async () => {
                const postHandles = handles.map((x) => x.postHandle);
                const result = await api.postApiService.deleteItems_POST({
                  posts: postHandles,
                });
                setSelectionMode(SelectionMode.None);
                setRefreshAlbum((prev) => prev + 1);
                await sleep(500);
                setConfirmModal(undefined);
              },
            });
          }}
        >
          Löschen
        </Button>
      </div>
    );
  }

  if (props.currentTitle.link && props.currentTitle.title) {
    return (
      <>
        <Link
          to={props.currentTitle.link}
          className={'scroller-top-navigation-title2'}
        >
          <IoChevronBackOutline className={'scroller-top-back'} />
          <div className={'nav-title2'}> {props.currentTitle.title}</div>
        </Link>
      </>
    );
  }
  return null;
};
