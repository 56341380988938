import React from 'react';
import {
  ScopedCssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ApiProvider } from '@backend/api';
import { EventProvider, UserProvider } from '@event-images/shared';
import { Skeleton } from './Skeleton';
import { webTheme } from './theme';
import 'dayjs/locale/de';
import moment from 'moment';
import 'moment/dist/locale/de';
import { IconContext } from 'react-icons';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'react-phone-input-2/lib/style.css';

moment.locale('de');

const App: React.FC = () => {
  /*
   <ErrorBoundary>

   */
  return (
    <ThemeProvider theme={webTheme}>
      <StyledEngineProvider injectFirst>
        <ScopedCssBaseline style={{ height: '100%', backgroundColor: 'white' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <IconContext.Provider value={{}}>
              <ApiProvider>
                <UserProvider>
                  <EventProvider>
                    <Skeleton />
                  </EventProvider>
                </UserProvider>
              </ApiProvider>
            </IconContext.Provider>
          </LocalizationProvider>
        </ScopedCssBaseline>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
