export const addOTP = (ac: AbortController, cb: (code: string) => void) => {
  if ('OTPCredential' in window) {
    const input = document.querySelector('input[autocomplete="one-time-code"]');
    if (!input) return;

    navigator.credentials
      .get({
        // @ts-ignore
        otp: { transport: ['sms'] },
        signal: ac.signal,
      })
      .then((otp) => {
        // @ts-ignore
        cb(otp?.code);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
