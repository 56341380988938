import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { CropModal, CropModalProps } from '../components/cropper/crop-modal';
import {
  UploaderModal,
  UploaderModalProps
} from '../components/uploader/uploader-modal';
import { Guid } from '@customTypes';
import { AlbumDto } from '@backend/models';
import { ConfirmModal, ConfirmModalProps } from '../components/confirm/confirm';
import { useApi } from '@backend/api';
import { useUserContext } from './UserContext';
import { clickUpload } from '../clickutils';

export enum BottomMenu {
  Start = 10,
  Galleries = 20,
  Gallery = 30,
  Scroller = 40,
  Upload = 50,
  Challenges = 60,
}

export enum SortMode {
  Date = 0,
  DateGrouped = 10,
  Likes = 20,
  Comments = 30,
  ChallengeId = 40,
}

export enum SelectionMode {
  None = 0,
  Selection = 10,
}

export interface CurrentTitle {
  link?: string;
  title?: string;
  customRender?: () => any;
}

interface EventContextValue {
  setBottomNavigationValue: (value: number) => void;
  bottomNavigationValue: number | undefined;
  setCropModal: Dispatch<SetStateAction<CropModalProps | undefined>>;
  setUploadAlbum: (val: UploaderModalProps | undefined) => void;
  cropModalOpen: boolean;
  refreshMenu: number;
  setRefreshMenu: Dispatch<SetStateAction<number>>;
  refreshAlbum: number;
  setRefreshAlbum: Dispatch<SetStateAction<number>>;
  commentPostHandle: Guid | undefined;
  setCommentPostHandle: Dispatch<SetStateAction<Guid | undefined>>;
  currentTitle: CurrentTitle | undefined;
  setCurrentTitle: Dispatch<SetStateAction<CurrentTitle | undefined>>;
  bottomMenu: BottomMenu;
  setBottomMenu: Dispatch<SetStateAction<BottomMenu>>;
  currentSortMode: SortMode;
  setConfirmModal: Dispatch<SetStateAction<ConfirmModalProps | undefined>>;
  setCurrentSortMode: Dispatch<SetStateAction<SortMode>>;
  changeSelectedPosts: (val: SelectedModel) => void;
  selectedPostsState: { [key: string]: SelectedModel };
  selectionMode: SelectionMode | undefined;
  setSelectionMode: (val: SelectionMode | undefined) => void;
  albums: AlbumDto[] | undefined;
  challengeAlbumId: number;
}

export const EventContext = createContext<EventContextValue>({} as any);

export const useEventContext = (): EventContextValue =>
  useContext(EventContext);

export interface SelectedModel {
  postHandle: Guid;
}

export const EventProvider: FC<PropsWithChildren<{}>> = (props) => {
  const api = useApi();

  const [rerenderUpload, setRerenderUpload] = useState(1);
  const [challengeAlbumId, setChallengeAlbumId] = useState(2);
  const [bottomNavigationValue, setBottomNavigationValue] = useState<number>();
  const [albums, setAlbums] = useState<AlbumDto[]>();
  const [cropModal, setCropModal] = useState<CropModalProps>();
  const [uploadAlbum, _setUploadAlbum] = useState<UploaderModalProps>();
  const [confirmModalProps, setConfirmModalProps] =
    useState<ConfirmModalProps>();
  const [refreshMenu, setRefreshMenu] = useState(1);
  const [refreshAlbum, setRefreshAlbum] = useState(1);
  const [commentPostHandle, setCommentPostHandle] = useState<Guid>();
  const [currentTitle, setCurrentTitle] = useState<CurrentTitle>();
  const [bottomMenu, setBottomMenu] = useState(BottomMenu.Start);
  const [currentSortMode, setCurrentSortMode] = useState(SortMode.Date);
  const [selectionMode, _setSelectionMode] = useState(SelectionMode.None);
  const [selectedPostsState, setSelectedPostsState] = useState<{
    [key: string]: SelectedModel;
  }>({});

  const { isLoggedIn } = useUserContext();

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    api.postApiService
      .getAlbums_POST({
        onlySelf: false
      })
      .then((r) => {
        const _albums = r.result?.albums || [];
        _albums.sort((a, b) => {
          return a.sortNumber > b.sortNumber ? -1 : 1;
        });
        setAlbums(_albums);
      });
  }, [isLoggedIn, api.postApiService]);

  const setUploadAlbum = useCallback((val: UploaderModalProps | undefined) => {
    _setUploadAlbum(val);
    if (val) {
      clickUpload()
    }
  }, []);

  const setSelectionMode = useCallback((val: SelectionMode | undefined) => {
    const newMode = val || SelectionMode.None;
    _setSelectionMode(newMode);
    if (newMode === SelectionMode.None) {
      setSelectedPostsState({});
    }
  }, []);

  const changeSelectedPosts = useCallback((val: SelectedModel) => {

    const key = '' + val.postHandle;

    setSelectedPostsState((prev) => {
      const n = { ...prev };
      if (n[key]) {
        delete n[key];
      } else {
        n[key] = {
          postHandle: val.postHandle
        };
      }
      return n;
    });


  }, []);

  const x = useMemo(
    () =>
      ({
        bottomNavigationValue: bottomNavigationValue,
        setBottomNavigationValue: setBottomNavigationValue,
        setCropModal: setCropModal,
        cropModalOpen: !!cropModal,
        setUploadAlbum: setUploadAlbum,
        refreshMenu: refreshMenu,
        setRefreshMenu: setRefreshMenu,
        commentPostHandle: commentPostHandle,
        setCommentPostHandle: setCommentPostHandle,
        refreshAlbum: refreshAlbum,
        setRefreshAlbum: setRefreshAlbum,
        currentTitle: currentTitle,
        setCurrentTitle: setCurrentTitle,
        setBottomMenu: setBottomMenu,
        bottomMenu: bottomMenu,
        currentSortMode: currentSortMode,
        setCurrentSortMode: setCurrentSortMode,
        changeSelectedPosts: changeSelectedPosts,
        selectionMode: selectionMode,
        setSelectionMode: setSelectionMode,
        setConfirmModal: setConfirmModalProps,
        selectedPostsState: selectedPostsState,
        challengeAlbumId: challengeAlbumId,
        albums: albums
      } as EventContextValue),
    [
      bottomMenu,
      bottomNavigationValue,
      changeSelectedPosts,
      commentPostHandle,
      cropModal,
      currentTitle,
      currentSortMode,
      refreshAlbum,
      refreshMenu,
      selectionMode,
      setSelectionMode,
      setUploadAlbum,
      selectedPostsState,
      setConfirmModalProps,
      albums,
      challengeAlbumId
    ]
  );
  return (
    <EventContext.Provider value={x}>
      <CropModal {...cropModal} />
      <UploaderModal {...uploadAlbum} key={'k' + rerenderUpload} onFinish={() => {
        if (uploadAlbum?.onFinish) {
          setRerenderUpload(prev => prev + 1);
          uploadAlbum?.onFinish();
        }
      }} />
      <ConfirmModal {...confirmModalProps} />

      {props.children}
    </EventContext.Provider>
  );
};
