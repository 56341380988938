import React from 'react';
import { Avatar } from '@mui/material';
import { UserDto } from '@backend/models';

export enum AvatarMode {
  Default = 0,
}

interface MyAvatarProps {
  mode?: AvatarMode;
  user: UserDto | undefined;
}

export const MyAvatar = (props: MyAvatarProps) => {
  return (
    <Avatar
      alt={props.user?.name}
      src={props.user?.avatar}
      sx={{ width: 30, height: 30 }}
    />
  );
};
