/* eslint-disable */
/**
 * v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Guid, StringDate } from '@customTypes';
import url from 'url';
import {
  HttpOptions,
  HttpClient,
  ResponseWrap,
  RequiredError,
  COLLECTION_FORMATS,
} from '@img/http-client';

import { PushNotificationDto } from '../model/pushNotificationDto';
import { SelfDto } from '../model/selfDto';
import { SelfRequestDto } from '../model/selfRequestDto';
import { UpdateUserDto } from '../model/updateUserDto';

export class UserApiService {
  constructor(protected httpClient: HttpClient) {}

  /**
   *
   * @param {PushNotificationDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // UserController.SetPushNotification  (EventImages.Server.Controllers)
  // userPushNotificationsPost
  public setPushNotification_POST(
    body?: PushNotificationDto,
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    const _path = `/user/pushNotifications`;
    const urlObj = url.parse(_path, true);
    const requestOptions = Object.assign({ method: 'POST' }, options);
    const headerParameter = {} as any;
    const queryParameter = {} as any;

    headerParameter['Content-Type'] = 'application/json';

    urlObj.query = Object.assign(
      {},
      urlObj.query,
      queryParameter,
      options.query
    );
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign(
      {},
      headerParameter,
      options.headers
    );
    const needsSerialization =
      <any>'PushNotificationDto' !== 'string' ||
      requestOptions.headers['Content-Type'] === 'application/json';
    requestOptions.body = needsSerialization
      ? JSON.stringify(body || {})
      : body || '';

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    };

    return this.httpClient.fetchWithoutResponse(
      'User',
      'userPushNotificationsPost',
      httpOptions
    );
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // UserController.RemoveAllPushNotifications  (EventImages.Server.Controllers)
  // userPushNotificationsRemovePost
  public removeAllPushNotifications_POST(
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    const _path = `/user/pushNotifications/remove`;
    const urlObj = url.parse(_path, true);
    const requestOptions = Object.assign({ method: 'POST' }, options);
    const headerParameter = {} as any;
    const queryParameter = {} as any;

    urlObj.query = Object.assign(
      {},
      urlObj.query,
      queryParameter,
      options.query
    );
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign(
      {},
      headerParameter,
      options.headers
    );

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    };

    return this.httpClient.fetchWithoutResponse(
      'User',
      'userPushNotificationsRemovePost',
      httpOptions
    );
  }

  /**
   *
   * @param {SelfRequestDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // UserController.GetSelf  (EventImages.Server.Controllers)
  // userSelfPost
  public getSelf_POST(
    body?: SelfRequestDto,
    options: any = {}
  ): Promise<ResponseWrap<SelfDto>> {
    const _path = `/user/self`;
    const urlObj = url.parse(_path, true);
    const requestOptions = Object.assign({ method: 'POST' }, options);
    const headerParameter = {} as any;
    const queryParameter = {} as any;

    headerParameter['Content-Type'] = 'application/json';

    urlObj.query = Object.assign(
      {},
      urlObj.query,
      queryParameter,
      options.query
    );
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign(
      {},
      headerParameter,
      options.headers
    );
    const needsSerialization =
      <any>'SelfRequestDto' !== 'string' ||
      requestOptions.headers['Content-Type'] === 'application/json';
    requestOptions.body = needsSerialization
      ? JSON.stringify(body || {})
      : body || '';

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    };

    return this.httpClient.fetch<SelfDto>('User', 'userSelfPost', httpOptions);
  }

  /**
   *
   * @param {UpdateUserDto} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  // UserController.UpdateUser  (EventImages.Server.Controllers)
  // userUpdatePost
  public updateUser_POST(
    body?: UpdateUserDto,
    options: any = {}
  ): Promise<ResponseWrap<Response>> {
    const _path = `/user/update`;
    const urlObj = url.parse(_path, true);
    const requestOptions = Object.assign({ method: 'POST' }, options);
    const headerParameter = {} as any;
    const queryParameter = {} as any;

    headerParameter['Content-Type'] = 'application/json';

    urlObj.query = Object.assign(
      {},
      urlObj.query,
      queryParameter,
      options.query
    );
    // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
    // delete urlObj.search;
    requestOptions.headers = Object.assign(
      {},
      headerParameter,
      options.headers
    );
    const needsSerialization =
      <any>'UpdateUserDto' !== 'string' ||
      requestOptions.headers['Content-Type'] === 'application/json';
    requestOptions.body = needsSerialization
      ? JSON.stringify(body || {})
      : body || '';

    const httpOptions: HttpOptions = {
      url: url.format(urlObj),
      options: requestOptions,
    };

    return this.httpClient.fetchWithoutResponse(
      'User',
      'userUpdatePost',
      httpOptions
    );
  }
}
