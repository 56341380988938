import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';

import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  AppInsightsErrorBoundary,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { Api, apiInstance } from '@backend/api';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      'InstrumentationKey=972c5039-782b-4025-a99c-5d37640b8b34;IngestionEndpoint=https://italynorth-0.in.applicationinsights.azure.com/;LiveEndpoint=https://italynorth.livediagnostics.monitor.azure.com/;ApplicationId=21c6094f-1e7e-45bb-a7b4-9088f653dc00',
    // *** If you're adding the Click Analytics plug-in, delete the next line. ***
    extensions: [reactPlugin],
    // *** Add the Click Analytics plug-in. ***
    // extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      // *** Add the Click Analytics plug-in. ***
      // [clickPluginInstance.identifier]: clickPluginConfig
    },
  },
});
appInsights.loadAppInsights();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const api = apiInstance;

root.render(
  // can cause unexplainable double rendering
  <StrictMode>
    <AppInsightsErrorBoundary
      onError={() => <h1>I believe something went wrong</h1>}
      appInsights={reactPlugin}
    >
      <App />
    </AppInsightsErrorBoundary>
  </StrictMode>
);
