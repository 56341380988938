import {
  Group,
  GroupedPostResult,
  GroupModel,
  PostModel,
  PostModelType,
  PostRow,
  PostsModel,
} from './post.models';
import { Api } from '@backend/api';
import { SortMode } from '@event-images/shared';
import moment from 'moment';
import { ChallengeDetailDto, PostQueryDto } from '@backend/models';

interface PostCacheModel {
  posts: PostsModel;
  cacheTimestampSec: number;
}

const sortPosts = (posts: PostModel[], sortMode: SortMode): PostModel[] => {
  const sortedPosts = [...posts];
  sortedPosts.sort((a, b) => {
    if (a.type === PostModelType.Upload) {
      return -1;
    }
    if (b.type === PostModelType.Upload) {
      return 1;
    }
    if (sortMode === SortMode.Likes) {
      if (a.data.likes == b.data.likes) {
        return a.timestampSec > b.timestampSec ? -1 : 1;
      }
      return a.data.likes > b.data.likes ? -1 : 1;
    }
    if (sortMode === SortMode.Comments) {
      if (a.data.commentsCount == b.data.commentsCount) {
        return a.timestampSec > b.timestampSec ? -1 : 1;
      }
      return a.data.commentsCount > b.data.commentsCount ? -1 : 1;
    }
    return a.timestampSec > b.timestampSec ? -1 : 1;
  });
  return sortedPosts;
};
const cache: { [key: string]: PostCacheModel } = {};

const _loadPosts = async (
  api: Api,
  query: PostQueryDto,
  forceReload: boolean
): Promise<PostsModel> => {
  const cacheKey = 'k' + JSON.stringify(query);
  const cacheEntry = cache[cacheKey];
  const nowInSec = new Date().getTime() / 1000;

  if (
    !forceReload &&
    cacheEntry &&
    cacheEntry.cacheTimestampSec > nowInSec - 5 * 60
  ) {
    return cacheEntry.posts;
  }
  const posts = await api.postApiService.getPosts_POST(query);

  const result = posts.result?.posts || [];

  const postModels: PostModel[] = [];
  for (const post of result) {
    const start = moment.utc(post.createdDate);
    postModels.push({
      type: PostModelType.Post,
      date: start,
      timestampSec: start.toDate().getTime(),
      data: post,
      key: post.handle,
    });
  }
  const model: PostsModel = {
    posts: postModels,
    title: posts.result?.title,
  };
  if (result.length) {
    cache[cacheKey] = {
      posts: model,
      cacheTimestampSec: nowInSec,
    };
  }
  return model;
};

export const loadPosts = async (
  api: Api,
  query: PostQueryDto,
  sortMode: SortMode,
  forceReload = false,
  filterOutPending = false
): Promise<PostsModel> => {
  const posts = await _loadPosts(api, query, forceReload);

  let p = posts.posts;
  if (filterOutPending) {
    p = p.filter((x) => !x.data.waiting);
  }

  const sortedPosts = sortPosts(p, sortMode);

  const model: PostsModel = {
    posts: sortedPosts,
    title: posts.title,
  };
  return model;
};

export const loadPostGrouped = async (
  api: Api,
  query: PostQueryDto,
  cols = 3,
  sortMode: SortMode,
  groupIntervalInMin = 10,
  forceReload = false,
  addAddItem = false,
  challenges: ChallengeDetailDto[] | undefined = undefined
): Promise<GroupedPostResult> => {
  const posts = await _loadPosts(api, query, forceReload);
  let waiting = false;
  // Group Post by date
  const groups: { [key: string]: GroupModel } = {};
  for (const post of posts.posts) {
    if (post.data.waiting) {
      waiting = true;
    }
    let groupKey = '';
    if (groupIntervalInMin > 0) {
      const start = post.date!;
      const remainder =
        groupIntervalInMin - (start.minute() % groupIntervalInMin);

      const groupStartDate = moment(start).add(remainder, 'minutes');
      groupKey = groupStartDate.format('YYYY-MM-DDTHH:mm');
    } else if (sortMode === SortMode.ChallengeId) {
      groupKey = '' + post.data.challenge?.id;
    }

    let group = groups[groupKey];
    if (!group) {
      if (groupIntervalInMin > 0) {
        const start = post.date!;
        groups[groupKey] = {
          groupName: start.format('D.M HH:mm'),
          groupKey: groupKey,
          posts: [],
          orderNumber: start.toDate().getTime() / 1000,
        };
      } else if (sortMode === SortMode.ChallengeId) {
        // @ts-ignore
        const challenge = challenges?.find((x) => x.id == groupKey);
        groups[groupKey] = {
          groupName: challenge?.text || '',
          groupKey: groupKey,
          posts: [],
          orderNumber: 0,
        };
      } else {
        groups[groupKey] = {
          groupName: '',
          groupKey: groupKey,
          posts: [],
          orderNumber: 0,
        };
      }
      group = groups[groupKey];
    }

    group.posts.push(post);
  }

  const sortedGroups = Object.values(groups);
  sortedGroups.sort((a, b) => (a.orderNumber > b.orderNumber ? -1 : 1));

  if (addAddItem) {
    if (!sortedGroups.length) {
      sortedGroups.push({
        groupKey: 'start',
        groupName: '',
        orderNumber: 0,
        posts: [],
      });
    }
    sortedGroups[0].posts.push({
      type: PostModelType.Upload,
      timestampSec: new Date().getTime(),
      data: undefined as any,
      key: 'upload',
    });
  }

  // Transform to Group Result
  const result: GroupedPostResult = {
    rows: [],
    rowGroups: [],
    rowGroupLengths: [],
    title: posts.title,
    waiting: waiting,
  };

  for (const groupModel of sortedGroups) {
    const group: Group = {
      groupKey: groupModel.groupKey,
      groupName: groupModel.groupName,
    };
    result.rowGroups.push(group);

    groupModel.posts = sortPosts(groupModel.posts, sortMode);

    let currentRow: PostRow = {
      cols: [],
    };

    let rowGroupLength = 0;
    for (
      let groupPostIndex = 0;
      groupPostIndex < groupModel.posts.length;
      groupPostIndex++
    ) {
      const post = groupModel.posts[groupPostIndex];
      if (groupPostIndex > 0 && groupPostIndex % cols === 0) {
        result.rows.push(currentRow);
        rowGroupLength++;
        currentRow = {
          cols: [],
        };
      }
      currentRow.cols.push(post);
    }

    if (currentRow.cols.length) {
      result.rows.push(currentRow);
      rowGroupLength++;
    }
    result.rowGroupLengths.push(rowGroupLength);
  }

  return result;
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
