

import { createTheme } from "@mui/material"

const defaultTheme = createTheme()

const primaryColor = "#0095F6"

export const webTheme = createTheme({
  shadows:['none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none','none'
    ,'none','none' ],

  typography: {
    fontSize: 16,
    fontFamily: `Roboto`,
    subtitle1: {
      marginTop: defaultTheme.spacing(4),
      marginBottom: defaultTheme.spacing(2),
      fontSize: 18.72,
      fontWeight: 600,
      borderBottom: "1px solid",
      borderColor: "#007994",
    },
    subtitle2: {
      fontSize: 16,
    },
    h3: {
      fontSize: "40px",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "140%",
      fontFamily: `EB Garamond`,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
    },
  },
})

