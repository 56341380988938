/* eslint-disable @typescript-eslint/no-explicit-any */
import { initializeApp } from '@firebase/app';
import {
  getMessaging,
  getToken,
  deleteToken,
  onMessage,
  isSupported,
} from '@firebase/messaging';
import { Api } from '@backend/api';
import { v4 as uuidv4 } from 'uuid';
/*
https://github.com/dimi-iv/firebase-messaging-web-app-example/blob/master/index.html
 */
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyB9KpFmsok0r_Q1IceYiovlLKudSBiDVLo',
  authDomain: 'fh-chat-6daae.firebaseapp.com',
  projectId: 'fh-chat-6daae',
  storageBucket: 'fh-chat-6daae.appspot.com',
  messagingSenderId: '597257248565',
  appId: '1:597257248565:web:7042deb96ff9b7aa0ef67c',
};
const vapidKey =
  'BJaehcqwGaK2jJ8QAuWxcsa8E4qKN6n62gYf6oaAW43RYe6JVzy7ELXMFBQpGjXyEOnCA1IqxfdJW-Joj6IHHqQ';
const debug = true;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  console.log('Foreground Message received: ', payload);
});
const deviceIdKey = 'dvi';
let deviceId = window.localStorage.getItem(deviceIdKey);
if (!deviceId) {
  deviceId = uuidv4();
  window.localStorage.setItem(deviceIdKey, deviceId);
}

const registerUserToken2 = async (
  registration: ServiceWorkerRegistration,
  api: Api
) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: vapidKey,
      serviceWorkerRegistration: registration,
    });

    if (!currentToken) {
      console.log(
        'No registration token available. Request permission to generate one.'
      );
      return;
    }

    await api.userApiService.setPushNotification_POST({
      deviceId: deviceId || '',
      deviceName: '',
      token: currentToken,
    });
  } catch (error: any) {
    console.log('Token coulnd not be registered: ', error);
  }
};

const registerUserToken = async (api: Api) => {
  try {
    const registrationName = 'firebase-messaging-sw.js';
    const registeredRegistration =
      await navigator.serviceWorker.getRegistration(registrationName);
    if (registeredRegistration) {
      registerUserToken2(registeredRegistration, api).then(() => {
        if (debug) {
          console.log('registered');
        }
      });
      return;
    }
    const registration = await navigator.serviceWorker.register(
      registrationName
    );
    let serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      if (debug) {
        console.log('Service worker installing');
      }
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      if (debug) {
        console.log('Service worker installed & waiting');
      }
    } else if (registration.active) {
      serviceWorker = registration.active;
      if (debug) {
        console.log('Service worker active');
      }
    }

    if (serviceWorker) {
      if (debug) {
        console.log('sw current state', serviceWorker.state);
      }
      if (serviceWorker.state === 'activated') {
        if (debug) {
          //If push subscription wasn't done yet have to do here
          console.log('sw already activated - Do whatever needed here');
        }
      }
      serviceWorker.addEventListener('statechange', function (e: any) {
        console.log('sw statechange : ', e.target.state);
        if (e.target.state === 'activated') {
          if (debug) {
            console.log(
              'Just now activated. now we can subscribe for push notification'
            );
          }
          registerUserToken2(registration, api).then(() => {
            if (debug) {
              console.log('registered');
            }
          });
        }
      });
    }
  } catch (error: any) {
    console.log('Service Worker could not be registered: ', error);
  }
};

const enableFirebaseNotifications = async (api: Api) => {
  await registerUserToken(api);
};

export interface RequestPermissionResponse {
  alertTitle: string;
  alertMessage: string;
}

export const requestPermission = async (
  api: Api
): Promise<RequestPermissionResponse | undefined> => {
  const supported = await isSupported();
  if (!supported) {
    return;
  }

  // Check whether notification permissions have already been granted
  if (Notification.permission === 'granted') {
    // If permissions have been granted,
    // enable Firebase notifications.
    if (debug) {
      console.log('Permission already granted');
    }
    await enableFirebaseNotifications(api);
    return;
  }

  // Otherwise, ask user for permission
  if (Notification.permission !== 'denied') {
    const permission = await Notification.requestPermission();
    // If the user accepts, enable Firebase Notifications
    if (permission === 'granted') {
      if (debug) {
        console.log('Permission just granted');
      }
      await enableFirebaseNotifications(api);
      return;
    }
  }
  if (debug) {
    console.log('Unable to get permission to notify.');
  }
};

export const deleteUserToken = async () => {
  await deleteToken(messaging);
};
