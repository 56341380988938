import './uploader.scss';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useApi } from '@backend/api';
import {
  Components,
  GroupedVirtuoso,
  StateSnapshot,
  VirtuosoHandle,
} from 'react-virtuoso';
import { loadPostGrouped } from '../../utils/postLoader';
import { MobileOverviewItem } from './mobile-overview-item';
import { MyContext } from './startpage.models';
import {
  SelectionMode,
  SortMode,
  useEventContext,
  useUserContext,
} from '@event-images/shared';
import { GroupedPostResult } from '../../utils/post.models';
import { AlbumHeader } from './album-header';
import { useParams } from 'react-router-dom';
import {
  AlbumDetailDto,
  ChallengeDetailDto,
  PostQueryDto,
} from '@backend/models';
import { loadChallenges } from '../../utils/challengeLoader';

export interface AlbumProps {
  myImages?: boolean;
}

const stateSnapshots: { [key: string]: StateSnapshot } = {};

export const Album = (props: AlbumProps) => {
  const api = useApi();
  const [isChallengeAlbum, setIsChallengeAlbum] = useState(false);
  const [groupedPosts, setGroupedPosts] = useState<GroupedPostResult>();
  const [albumDetail, setAlbumDetail] = useState<AlbumDetailDto>();
  const [cols, setCols] = useState<number>(3);
  const [deleteButton, setDeleteButton] = useState(false);
  const {
    refreshAlbum,
    setRefreshAlbum,
    setCurrentTitle,
    setCurrentSortMode,
    currentSortMode,
    challengeAlbumId,
    setSelectionMode,
  } = useEventContext();
  const virtuosoRef = React.useRef<VirtuosoHandle>(null);

  const { user } = useUserContext();
  const par = useParams();
  const albumId = (par.albumId || 0) as any;
  const challengeId = (par.challengeId || 0) as any;

  const inProgress = useRef(false);
  const lastRefreshIdRef = useRef(0);

  const context = useMemo<MyContext>(() => {
    return {
      albumDetail: albumDetail,
      setAlbumDetail: setAlbumDetail,
      sortMode: currentSortMode,
      setSortMode: setCurrentSortMode,
      isChallengeAlbum: isChallengeAlbum,
      deleteButton: deleteButton,
    };
  }, [
    albumDetail,
    currentSortMode,
    setCurrentSortMode,
    isChallengeAlbum,
    deleteButton,
  ]);

  const albumEditable = !!(user?.user?.admin || props.myImages);

  const innerWidth = window.innerWidth;
  useEffect(() => {
    return () => {
      setSelectionMode(SelectionMode.None);
    };
  }, [setSelectionMode]);

  useLayoutEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      virtuosoRef.current?.getState((snapshot) => {
        stateSnapshots['a' + albumId] = snapshot;
      });
    };
  }, [albumId, virtuosoRef]);

  useEffect(() => {
    if (refreshAlbum < 0) {
      return;
    }
    if (inProgress.current) {
      return;
    }
    inProgress.current = true;

    const asyncEffect = async () => {
      let album: AlbumDetailDto | undefined = undefined;
      if (albumId > 0) {
        const albumR = await api.postApiService.getAlbum_GET(albumId);
        album = albumR.result;
      }
      let cols = 3;
      if (innerWidth > 1024) {
        cols = 5;
      } else if (innerWidth > 768) {
        cols = 4;
      }
      let sortMode = currentSortMode;
      let groupInvervalInMin = sortMode == SortMode.DateGrouped ? 30 : 0;

      const query: PostQueryDto = {};
      if (props.myImages) {
        query.self = true;
        album = {
          title: 'Eigene Bilder',
          editable: true,
        } as any;
        setDeleteButton(true);
      } else if (albumId) {
        query.albumId = albumId;
      }
      let _challenges: ChallengeDetailDto[] | undefined = undefined;
      if (albumId == challengeAlbumId) {
        groupInvervalInMin = 0;
        sortMode = SortMode.ChallengeId;
        _challenges = await loadChallenges(api, false);

      }

      setCurrentTitle({
        title: album?.title,
        link: '/challenges',
      });

      const loadPosts = await loadPostGrouped(
        api,
        query,
        cols,
        sortMode,
        groupInvervalInMin,
        refreshAlbum !== lastRefreshIdRef.current,
        album?.editable && sortMode !== SortMode.ChallengeId,
        _challenges
      );
      setCols(cols);
      setGroupedPosts(loadPosts);
      setAlbumDetail(album);
      setIsChallengeAlbum(sortMode === SortMode.ChallengeId);
      lastRefreshIdRef.current = refreshAlbum;

      if (loadPosts.waiting) {
        setTimeout(() => {
          setRefreshAlbum((prev) => prev + 1);
        }, 5 * 1000);
      }
      inProgress.current = false;
    };
    void asyncEffect();
  }, [
    challengeAlbumId,
    currentSortMode,
    props.myImages,
    api,
    inProgress,
    albumId,
    challengeId,
    refreshAlbum,
    setRefreshAlbum,
    innerWidth,
    setCurrentTitle,
  ]);

  if (!groupedPosts) {
    return;
  }

  return (
    <GroupedVirtuoso
      ref={virtuosoRef}
      style={{
        height: '100%',
        // @ts-ignore
        '--element-cols': cols,
      }}
      context={context}
      components={gridComponents}
      groupCounts={groupedPosts.rowGroupLengths}
      className={'ss'}
      restoreStateFrom={stateSnapshots['a' + albumId]}
      groupContent={(index) => {
        const group = groupedPosts.rowGroups[index];
        if (!group) {
          console.log('Null group?');
          return null;
        }
        return (
          <div
            className={
              'startpage-group' + (isChallengeAlbum ? ' challenge-album' : '')
            }
          >
            <span>{group.groupName}</span>
          </div>
        );
      }}
      itemContent={(rowIndex, groupIndex) => {
        const row = groupedPosts.rows[rowIndex];
        if (!row) {
          console.log('Null row?');
          return null;
        }

        return (
          <div className={'post-line'}>
            {row.cols.map((x) => (
              <MobileOverviewItem
                key={x.key}
                post={x}
                albumId={albumId}
                editable={albumEditable}
              />
            ))}
          </div>
        );
      }}
    />
  );
};

const gridComponents: Components<any, MyContext> = {
  Header: (props) => {
    if (!props.context) {
      return null;
    }

    return <AlbumHeader context={props.context} />;
  },
  List: React.forwardRef((props, ref) => {
    return (
      <div className="scroller-list" {...props} ref={ref}>
        {props.children}
      </div>
    );
  }),
};
