import React, {
  useEffect,
  useState,
} from 'react';
import { useApi } from '@backend/api';

// @ts-ignore
import { useParams } from 'react-router-dom';
import { EType, PostDto } from '@backend/models';

interface ScrollerViewProps {}

export const SingleScroller = (props: ScrollerViewProps) => {
  const api = useApi();
  const [post, setPost] = useState<PostDto>();

  const par = useParams();
  const albumId = par.albumId as any;

  const postHandle = par.postHandle || '';

  useEffect(() => {
    if (!albumId || !postHandle) {
      return;
    }
    const asyncFn = async () => {
      const _post = await api.postApiService.getPost_POST(postHandle);
      setPost(_post.result);
    };
    void asyncFn();
  }, [albumId, api.postApiService, postHandle]);

  if (!post) {
    return <div></div>;
  }
  return (
    <div className={'scroller-snap-container'}>
      <div
        className="scroller-list2"
        id="scroller-list2"
        style={{
          height:'100%',
          // @ts-ignore
          '--element-height': '100%',
        }}
      >
        <div>
          {post.type === EType.Video && (
            <video
              src={post.images?.video}
              autoPlay={true}
              controls
              poster={post.images?.i640w}
            />
          )}
          {post.type === EType.Image && <img src={post.images?.i640w} />}
        </div>
      </div>
    </div>
  );
};
