import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useApi } from '@backend/api';
import { debounce } from '@mui/material';
import { useEventContext } from '@event-images/shared';
import { ChallengeDetailDto } from '@backend/models';
import './challenge.scss';
import { useParams } from 'react-router-dom';
import { loadChallenges } from '../../utils/challengeLoader';
import { ChallengeDetail } from './ChallengeDetail';
import { ChallengeList } from './ChallengeList';

export interface ChallengeProps {}

export const Challenge = (props: ChallengeProps) => {
  const api = useApi();
  const [challenges, setChallenges] = useState<ChallengeDetailDto[]>([]);
  const [refreshChallenge, setRefreshChallenge] = useState(1);
  const { setCropModal, setCurrentTitle, challengeAlbumId, albums } =
    useEventContext();
  const [refreshScroller, setRefreshScroller] = useState(1);

  const par = useParams();
  const challengeId = (par.challengeId || 0) as any;

  const inProgress = useRef(false);
  useEffect(() => {
    setCurrentTitle(undefined);
  }, [setCurrentTitle]);

  useEffect(() => {
    if (refreshChallenge < 0) {
      return;
    }
    if (inProgress.current) {
      return;
    }
    inProgress.current = true;

    const asyncEffect = async () => {
      const _challenges = await loadChallenges(api, true);
      setChallenges(_challenges);
      inProgress.current = false;
    };
    void asyncEffect();
  }, [api, inProgress, refreshChallenge]);

  const challenge = challenges.find((x) => x.id == challengeId);

  useEffect(() => {
    if (challenge) {
      setCurrentTitle({
        title: 'Challenges',
        link: '/challenges',
      });
    }
  }, [setCurrentTitle, challenge]);
  if (!albums) {
    return null;
  }
  if (challenge) {
    return (
      <ChallengeDetail
        challenge={challenge}
        setRefreshChallenge={setRefreshChallenge}
      />
    );
  }
  return (
    <ChallengeList
      albums={albums}
      challenges={challenges}
      setRefreshScroller={setRefreshChallenge}
    />
  );
};
