import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AlbumDto, ChallengeDetailDto } from '@backend/models';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { AlbumSelection } from '../../components/album-selection/AlbumSelection';
import { debounce, TextField } from '@mui/material';

export interface ChallengeListProps {
  albums: AlbumDto[];
  challenges: ChallengeDetailDto[];
  setRefreshScroller: React.Dispatch<React.SetStateAction<number>>;
}

let query: string | undefined;
export const ChallengeList = (props: ChallengeListProps) => {
  const [challenges, setChallenges] = useState<ChallengeDetailDto[]>(
    props.challenges
  );

  const requestSearch = useCallback(() => {
    let all = [...props.challenges];
    if (query) {
      const q = query.toLowerCase();
      all = all.filter((x) => x.text.toLowerCase().includes(q));
    }
    setChallenges(all);
  }, [props.challenges]);

  const debouncedSearch = useMemo(
    () => debounce(requestSearch, 250),
    [requestSearch]
  );

  useEffect(() => {
    requestSearch();
  }, [requestSearch]);
  return (
    <>
      <div className={'startpage startpage-challenge'}>
        <div className={'header-title'}>Foto-Alben</div>
        <AlbumSelection albums={props.albums} finished={() => {}} />
        <div className={'header-title'}>Challenges</div>
        <div className={'search-field'}>
          <TextField
            variant={'standard'}
            size={'small'}
            margin="normal"
            fullWidth
            label="Suchen..."
            onChange={(e) => {
              query = e.target.value;
              debouncedSearch();
            }}
          />
        </div>
      </div>
      <div className={'challenges'}>
        {challenges.map((challenge, index) => {
          return (
            <Link to={'/challenges/' + challenge.id} key={challenge.handle}>
              <div className={'challenge-1'}>
                <div className={'challenge-1a'}>
                  <div className={'text'}>{challenge.text}</div>
                  <div className={'arrow'}>
                    <ArrowForwardIosIcon />
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};
