import React from 'react';
import { useApi } from '@backend/api';
import { Button } from '@mui/material';
import { CropResult, useEventContext } from '@event-images/shared';
import { ChallengeDetailDto } from '@backend/models';
import { IoAddCircleOutline, IoBrushOutline } from 'react-icons/io5';
import { InnerItem } from '../mobile-scroller/scroller-inner';
import { PostModelType } from '../../utils/post.models';
import { usePostContext } from '../mobile-scroller/usePostContext';

export interface ChallengeDetailProps {
  challenge: ChallengeDetailDto;
  setRefreshChallenge: React.Dispatch<React.SetStateAction<number>>;
}

export const ChallengeDetail = (props: ChallengeDetailProps) => {
  const setRefreshChallenge = props.setRefreshChallenge;
  const challenge = props.challenge;
  const challengeResults = challenge.results || [];
  const api = useApi();
  const context = usePostContext(props.setRefreshChallenge);

  const { setCropModal, setCurrentTitle, challengeAlbumId, albums } =
    useEventContext();

  const addChallengeResult = (challenge: ChallengeDetailDto) => {
    setCropModal({
      onCancel: () => {
        setCropModal(undefined);
      },
      title: 'Challenges',
      subTitle: challenge.text,
      onFinish: async (result: CropResult) => {
        const albumUpdated =
          await api.challengeApiService.createChallengResult_POST({
            title: result.title,
            cropImageId: result.cropImageId,
            challengeHandle: challenge.handle,
          });
        setCropModal(undefined);
        setRefreshChallenge((prev) => prev + 1);
      },
      aspectRatio: 0.666,
      cropShape: 'rect',
      open: true,
      startImageFile: undefined,
      startCropImageId: challenge.startCropId,
      newMaxLength: 500,
      createVariants: true,
      autoUpload: true,
    });
  };

  const own = challengeResults.find((x) => x.self);
  return (
    <>
      <div className={'startpage startpage-challenge'}>
        <div className={'header-title'}>Challenges</div>
      </div>
      <div className={'challenges'}>
        <div className={'challenge-1'}>
          <div
            className={'challenge-1a'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addChallengeResult(challenge);
            }}
          >
            <div className={'text'}>{challenge.text}</div>
          </div>
        </div>

        <div>
          {!!challenge.startCropId && (
            <Button
              sx={{ color: '#000000' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addChallengeResult(challenge);
              }}
              variant={'text'}
            >
              <IoBrushOutline /> Foto Wechseln
            </Button>
          )}
          {!challenge.startCropId && (
            <Button
              variant={'text'}
              sx={{ color: '#000000' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addChallengeResult(challenge);
              }}
            >
              <IoAddCircleOutline />{' '}
              <span style={{ marginLeft: '6px' }}>Foto Hochladen</span>
            </Button>
          )}
        </div>
        {!!own && (
          <>
            <div className={'header-title'}>Dein Foto</div>
            <div className={'challenges'}>
              <div className="scroller-list2">
                <InnerItem
                  index={0}
                  context={context}
                  noTitle={true}
                  postWrapper={{
                    post: {
                      key: own.handle,
                      date: undefined,
                      type: PostModelType.Post,
                      timestampSec: 0,
                      data: own,
                    },
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className={'challenges'}>
          {challengeResults.map((challengeResult, index) => {
            if (challengeResult.self) {
              return;
            }
            if (challenge.results?.[0]) {
              return (
                <div className="scroller-list2">
                  <InnerItem
                    index={index}
                    context={context}
                    noTitle={true}
                    postWrapper={{
                      post: {
                        key: challengeResult.handle,
                        date: undefined,
                        type: PostModelType.Post,
                        timestampSec: 0,
                        data: challengeResult,
                      },
                    }}
                  />
                </div>
              );
            }

            if (challengeResult.self) {
              return null;
            }
            return (
              <div>
                <img src={challengeResult?.images?.i640w} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
