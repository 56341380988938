import { Box, Button, Stack, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Api, useApi } from '@backend/api';
import { useUserContext } from '@event-images/shared';
import Lottie from 'lottie-react';
import flowersAnimation from '../../lottie/flowers.json';
import ringsAnimation from '../../lottie/rings.json';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as PhoneInput from 'react-phone-input-2';
import VerificationInput from 'react-verification-input';
import { addOTP } from './login-phone.utils';
import LoadingButton from '@mui/lab/LoadingButton';

export interface LoginPhoneViewProps {
}

const loginStep2 = async (
  api: Api, phoneNumber: string,
  mcode: string, path: string, pin: string, usedName: string,
  setLoading: () => void,
  setError: (val: string | undefined) => void
) => {
  setLoading();
  const resultS = await api.authenticationApiService.reAuthStep2_POST({
    telephone: phoneNumber,
    code: mcode,
    path: path,
    pin: pin,
    usedname: usedName
  });
  const result = resultS.result;
  if (result?.success) {
    document.location.href = '/';
  } else {
    setError(result?.error?.[0]?.description);
  }
};
export const LoginPhoneView = (props: LoginPhoneViewProps) => {
    const api = useApi();
    const [error, setError] = useState<string>();
    const { doLogin, autoLogin } = useUserContext();
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [isStep2, setIsStep2] = useState(false);
    const [code, setCode] = useState<string>();
    const acRef = useRef<AbortController>(new AbortController());
    const usedNameRef = useRef<HTMLInputElement>();
    const params = useParams();
    const pinRef = useRef<HTMLInputElement>();
    const [loading, setLoading] = useState(false);

    const handleSubmit2 = async (_code: string | undefined = undefined) => {
      const mcode = _code || code;
      if (!phoneNumber) {
        return;
      }
      if (!isStep2) {
        setLoading(true);
        const result = await api.authenticationApiService.reAuthStep1_POST({
          telephone: phoneNumber,
          path: params.spath || '',
          pin: pinRef.current?.value || ''
        });
        setIsStep2(true);
        setTimeout(() => {
          addOTP(acRef.current, (__code) => {
            setCode(__code);

            loginStep2(api, phoneNumber, __code, params.spath || '', pinRef.current?.value || '', usedNameRef.current?.value || '',
              () => {
                setLoading(true);
              },
              err => {
                setLoading(false);
                setError(err);
              });
          });
        }, 200);
        setLoading(false);
        return;
      }

      loginStep2(api, phoneNumber, mcode || '', params.spath || '', pinRef.current?.value || '', usedNameRef.current?.value || '', () => {
        setLoading(true);
      }, err => {
        setLoading(false);
        setError(err);
      });
    };
    const handleSubmit = async (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      handleSubmit2();
    };

    return (
      <>
        <div className={'skeleton--center-image-container'}>
          <Lottie
            className={'login-logo'}
            animationData={ringsAnimation}
            loop={true}
          />
          <h4>Gold-Berger</h4>
        </div>
        <Stack className={'login-stack'}>
          <Box
            component="form"
            noValidate
            className={'login-form'}
            onSubmit={handleSubmit}
          >
            <TextField
              inputRef={usedNameRef}
              margin="normal"
              required
              fullWidth
              variant={'standard'}
              label="Dein Name"
              id="usedname"
              name="usedname"
              autoFocus
            />
            {!autoLogin && (
              <TextField
                inputRef={pinRef}
                variant={'standard'}
                margin="normal"
                required
                fullWidth
                id="pin"
                label="Pin"
                name="pin"
                autoFocus
              />
            )}
            {!isStep2 && (
              <PhoneInput.default
                country={'at'}
                value={phoneNumber || ''}
                onlyCountries={['at', 'rs', 'de']}
                placeholder={'+43 664 123 456 78'}
                areaCodes={[]}
                disabled={isStep2}
                onChange={(e) => {
                  setPhoneNumber(e);
                }}
              />
            )}
            {isStep2 && (
              <>
                <div className={'sentCode'}>
                  Wenn Deine Telefonnummer gültig ist, haben wir Dir per SMS einen
                  Code gesendet.
                  <br />
                  Bitte gib diesen hier ein.
                </div>
                <VerificationInput
                  placeholder={'_'}
                  inputProps={{ autoComplete: 'one-time-code' }}
                  value={code}
                  onChange={(c) => setCode((c || '').toUpperCase())}
                  onComplete={(_code) => {
                    const co = (_code || '').toUpperCase();
                    setCode(co);
                    handleSubmit2(co);
                  }}
                />
              </>
            )}
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              className={'login-button'}
              sx={{ mt: 3, mb: 2 }}
            >
              Starten
            </LoadingButton>
            <div className={'sentCode2'}>
              <Link to={'/' + (params.spath || '')}>Zurück</Link>
            </div>
            <div className={'sentCode'}>{error}</div>
          </Box>
          <Lottie
            className={'flowers'}
            animationData={flowersAnimation}
            loop={true}
          />
        </Stack>
      </>
    );
  }
;
