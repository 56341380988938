import { PostDto } from '@backend/models';
import { Moment } from 'moment';

export interface GroupedPostResult {
  rowGroups: Group[];
  rowGroupLengths: number[];
  rows: PostRow[];
  title: string | undefined;
  waiting: boolean;
}

export interface PostRow {
  cols: PostModel[];
}

export interface Group {
  groupKey: string;
  groupName: string;
}

export interface GroupModel {
  orderNumber: number;
  groupKey: string;
  groupName: string;
  posts: PostModel[];
}

export enum PostModelType {
  Post = 0,
  Upload = 10,
}

export interface PostsModel {
  posts: PostModel[];
  title: string | undefined;
}

export interface PostModel {
  type: PostModelType;
  timestampSec: number;
  date?: Moment;
  data: PostDto;
  key: string;
}
