const isElementInViewport = (el: Element): boolean =>{
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
};

export const handleVideos = (scollerId:string): void => {
  const scrollerX = document.getElementById(scollerId);
  if (!scrollerX) {
    return;
  }
  const videos = scrollerX.getElementsByTagName('VIDEO');
  for (let i = 0; i < videos.length; i++) {
    const video = videos[i] as HTMLVideoElement;
    const isInViewPort = isElementInViewport(video);
    const  src=  video.getAttribute('src')
    const  dataSrc=   video.getAttribute('data-src')
    if(isInViewPort){
      if(dataSrc && !src){
        video.setAttribute('src',dataSrc )
        video.play()
      }
    }else {
      if(src && dataSrc){
        video.pause()
        video.removeAttribute('src')
      }
    }
  }
};
