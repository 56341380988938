import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { httpClient } from '../client/http-client.instance';
import { HttpClient } from '@img/http-client';
import { UploadApiService } from '../swagger/api/upload.api.service';
import { PostApiService } from '../swagger/api/post.api.service';
import { AuthenticationApiService } from '../swagger/api/authentication.api.service';
import { UserApiService } from '../swagger/api/user.api.service';
import { ChallengeApiService } from '../swagger/api/challenge.api.service';

export * from '../client/http-client.instance';

export class Api {
  public readonly postApiService: PostApiService;
  public readonly uploadApiService: UploadApiService;
  public readonly authenticationApiService: AuthenticationApiService;
  public readonly userApiService: UserApiService;
  public readonly challengeApiService: ChallengeApiService;

  constructor(httpClient: HttpClient) {
    this.postApiService = new PostApiService(httpClient);
    this.uploadApiService = new UploadApiService(httpClient);
    this.authenticationApiService = new AuthenticationApiService(httpClient);
    this.userApiService = new UserApiService(httpClient);
    this.challengeApiService = new ChallengeApiService(httpClient);
  }
}

export const apiInstance = new Api(httpClient);
export const ApiContext = createContext<Api>(apiInstance);

export const useApi = (): Api => useContext(ApiContext);

export const ApiProvider: React.FC<PropsWithChildren<{}>> = (props) => {
  const instance = useMemo(() => new Api(httpClient), []);

  return (
    <ApiContext.Provider value={instance}>{props.children}</ApiContext.Provider>
  );
};
