import { Badge, IconButton, Stack } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React, { useState } from 'react';
import { ResponsiveStyleValue } from '@mui/system';

interface LikeButtonProps {
  likes: number;
  liked: boolean;
  asStack?: boolean;
  stackDirection?: ResponsiveStyleValue<
    'row' | 'row-reverse' | 'column' | 'column-reverse'
  >;
  asBadge?: boolean;
  setLiked: (value: boolean) => void;
}

// Item contents are cached properly with React.memo
export const LikeButton = (props: LikeButtonProps) => {
  const [isLiked, setIsLike] = useState(props.liked);
  const [didLiked, setDidLiked] = useState(false);
  const [likes, setLikes] = useState(props.likes);

  if (props.asStack) {
    if (isLiked) {
      return (
        <Stack
          direction={props.stackDirection || 'row'}
          className={'like-button'}
        >
          <IconButton
            className={'my-heart ' + (didLiked ? 'my-heart--didliked' : '')}
            sx={{ color: '#FF0000' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsLike(false);
              props.setLiked(false);
              setLikes(props.likes);
            }}
          >
            <FavoriteIcon />
          </IconButton>
          <div className={'counter'}>{likes > 0 ? likes : ''}</div>
        </Stack>
      );
    }

    return (
      <Stack
        direction={props.stackDirection || 'row'}
        className={'like-button'}
      >
        <IconButton
          sx={{ color: '#FFFFFF' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsLike(true);
            setDidLiked(true);
            props.setLiked(true);
            setLikes(props.likes + 1);
          }}
        >
          <FavoriteBorderIcon />
        </IconButton>
        <div className={'counter'}>{likes > 0 ? likes : ''}</div>
      </Stack>
    );
  }

  if (props.asBadge) {
    if (isLiked) {
      return (
        <Stack direction={'row'} className={'like-button'}>
          <IconButton
            className={'my-heart ' + (didLiked ? 'my-heart--didliked' : '')}
            sx={{ color: '#FF0000' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsLike(false);
              props.setLiked(false);
              setLikes(props.likes - 1);
            }}
          >
            {likes ? (
              <Badge color="secondary" badgeContent={likes || ''}>
                <FavoriteIcon />
              </Badge>
            ) : (
              <FavoriteIcon />
            )}
          </IconButton>
        </Stack>
      );
    }

    return (
      <Stack direction={'row'} className={'like-button'}>
        <IconButton
          sx={{ color: '#000000' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsLike(true);
            setDidLiked(true);
            props.setLiked(true);
            setLikes(props.likes + 1);
          }}
        >
          {likes ? (
            <Badge color="secondary" badgeContent={likes || ''}>
              <FavoriteBorderIcon />
            </Badge>
          ) : (
            <FavoriteBorderIcon />
          )}
        </IconButton>
      </Stack>
    );
  }
  if (isLiked) {
    return (
      <Stack direction={'row'} className={'like-button'}>
        <IconButton
          className={'my-heart ' + (didLiked ? 'my-heart--didliked' : '')}
          sx={{ color: '#FF0000' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsLike(false);
            props.setLiked(false);
            setLikes(props.likes - 1);
          }}
        >
          <FavoriteIcon />
        </IconButton>
      </Stack>
    );
  }

  return (
    <Stack direction={'row'} className={'like-button'}>
      <IconButton
        sx={{ color: '#000000' }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsLike(true);
          setDidLiked(true);
          props.setLiked(true);
          setLikes(props.likes + 1);
        }}
      >
        <FavoriteBorderIcon />
      </IconButton>
    </Stack>
  );
};
