import React, { useEffect, useRef, useState } from 'react';
import { Components, Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useApi } from '@backend/api';
import { loadPosts } from '../../utils/postLoader';
import { PostWrapper } from './scroller.models';
// @ts-ignore
import { shareOnMobile } from 'react-mobile-share';
import { itemContent } from './scroller-inner';
import { useParams } from 'react-router-dom';
import { CurrentTitle, useEventContext } from '@event-images/shared';
import { handleVideos } from './scroller.utils';
import { PostQueryDto } from '@backend/models';
import { usePostContext } from './usePostContext';

interface ScrollerViewProps {
  myImages?: boolean;
}

export const ScrollerView = (props: ScrollerViewProps) => {
  const api = useApi();
  const [initialTopMostItemIndex, setInitialTopMostItemIndex] =
    useState<number>();
  const [refreshScroller, setRefreshScroller] = useState(1);
  const [posts, setPosts] = useState<PostWrapper[]>();
  const inProgress = useRef(false);

  const currentAlbumId = useRef<number>();
  const currentPostHandle = useRef<string>();
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const { commentPostHandle, setCurrentTitle, currentSortMode } =
    useEventContext();
  const lastRefreshIdRef = useRef(0);

  const par = useParams();
  const albumId = (par.albumId || 0) as any;
  const challengeId = (par.challengeId || 0) as any;

  const postHandle = par.postHandle || '';
  const context = usePostContext(setRefreshScroller);

  useEffect(() => {
    if (!posts || !virtuosoRef.current) {
      return;
    }
    if (
      currentAlbumId.current === albumId &&
      currentPostHandle.current === postHandle
    ) {
      return;
    }

    const postIndex = posts.findIndex((x) => x.post.data.handle === postHandle);
    if (postIndex > -1) {
      virtuosoRef.current.scrollToIndex({
        index: postIndex,
        align: 'start',
        behavior: 'auto',
      });
    }
    currentAlbumId.current = albumId;
    currentPostHandle.current = postHandle;
  }, [
    posts,
    postHandle,
    albumId,
    currentAlbumId,
    currentPostHandle,
    virtuosoRef,
  ]);

  useEffect(() => {
    if (refreshScroller < 0) {
      return;
    }
    if (inProgress.current) {
      return;
    }
    inProgress.current = true;
    const query: PostQueryDto = {};
    if (props.myImages) {
      query.self = true;
    } else if (albumId) {
      query.albumId = albumId;
    }
    loadPosts(api, query, currentSortMode, true).then((_posts) => {
      const _postsWrapper: PostWrapper[] = _posts.posts.map((x) => ({
        post: x,
      }));
      const postIndex = _postsWrapper.findIndex(
        (x) => x.post.data.handle === postHandle
      );
      if (postIndex > -1) {
        setInitialTopMostItemIndex(postIndex);
      } else {
        setInitialTopMostItemIndex(undefined);
      }
      setPosts(_postsWrapper);
      const t: CurrentTitle = {
        link: '/album/' + albumId,
        title: _posts.title || '',
      };
      setCurrentTitle(t);
      inProgress.current = false;
    });
  }, [
    props.myImages,
    api,
    inProgress,
    albumId,
    postHandle,
    currentSortMode,
    setCurrentTitle,
    refreshScroller,
  ]);

  const h = document.body.scrollHeight - 50 - 45;
  if (!posts?.length) {
    return <div></div>;
  }

  return (
    <Virtuoso
      ref={virtuosoRef}
      data={posts}
      initialTopMostItemIndex={initialTopMostItemIndex}
      overscan={h * 3}
      fixedItemHeight={h}
      style={{
        // @ts-ignore
        '--element-height': h + 'px',
      }}
      className={
        'scroller-snap-container' +
        (commentPostHandle ? ' scroller-snap-container--comment' : '')
      }
      isScrolling={(isScrolling) => {
        if (!isScrolling) {
          handleVideos('scroller-list2');
        }
      }}
      components={gridComponents}
      context={context}
      computeItemKey={(index, post, context) => '' + index}
      itemContent={itemContent}
    />
  );
};

const gridComponents: Components<any, any> = {
  List: React.forwardRef((props, ref) => {
    return (
      <div className="scroller-list2" id="scroller-list2" {...props} ref={ref}>
        {props.children}
      </div>
    );
  }),
};
