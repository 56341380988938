import React from 'react';
import { useUserContext } from '@event-images/shared';
import './QrCode.scss';

export interface QRCodeProps {}

export const QRCode = (props: QRCodeProps) => {
  const { user } = useUserContext();

  return (
   <div className={'qrcode'}>
     <img src={'data:image/png;base64,'+user?.user?.qrCode}/>
   </div>
  );
};
