import React from 'react';
import { AlbumDto } from '@backend/models';
import { Link } from 'react-router-dom';
import { useUserContext } from '@event-images/shared';
import { IoTelescopeOutline } from 'react-icons/io5';
import { IoCameraReverseOutline } from 'react-icons/io5';

export interface AlbumSelectionProps {
  albums: AlbumDto[];
  finished: (prop: AlbumDto) => void;
}

export const AlbumSelection = (props: AlbumSelectionProps) => {
  const { user } = useUserContext();
  return (
    <div className={'album-overview'}>
      <Link to={'/my-images'}>
        <div>
          {user?.user?.avatar ? (
            <img src={user?.user?.avatar} />
          ) : (
            <div className={'img-placeholder'}>
              <IoCameraReverseOutline />
            </div>
          )}

          <div className={'album-title'}>Eigene Bilder</div>
        </div>
      </Link>
      {props.albums.map((album) => {
        return (
          <Link key={'h' + album.albumId} to={'/album/' + album.albumId}>
            <div>
              <img src={album.avatarUrl} />
              <div className={'album-title'}>{album.title}</div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};
