import React, { useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoHome } from 'react-icons/io5';
import {
  BottomMenu,
  useEventContext,
  useUserContext,
} from '@event-images/shared';
import { IoHomeOutline } from 'react-icons/io5';

import { IoImagesOutline } from 'react-icons/io5';
import { IoImages } from 'react-icons/io5';
import { TfiArrowsVertical } from 'react-icons/tfi';

import { IoGrid } from 'react-icons/io5';

import { IoGridOutline } from 'react-icons/io5';
import { IoCameraOutline } from 'react-icons/io5';
import { IoCamera } from 'react-icons/io5';
import { IoAddCircleOutline } from 'react-icons/io5';
import { IoAddCircle } from 'react-icons/io5';

import { TbGridDots } from 'react-icons/tb';
import { IoFootball } from 'react-icons/io5';
import { MdOutlineSwipeDown } from 'react-icons/md';
import { MdOutlineSwipeVertical } from 'react-icons/md';
import { MdSwipeVertical } from 'react-icons/md';
import { PiDotsNineBold } from 'react-icons/pi';
import { PiDotsNine } from 'react-icons/pi';

import { IoFootballOutline } from 'react-icons/io5';

interface BottomNavProps {}

export const BottomNav = (props: BottomNavProps) => {
  // const pathname = document.location.pathname;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const {
    setBottomMenu,
    challengeAlbumId,
    bottomMenu,
    currentTitle,
    setRefreshAlbum,
    setUploadAlbum,
  } = useEventContext();

  const par = useParams();
  const albumId = (par.albumId || user?.defaultAlbumId || 0) as any;
  const postHandle = par.postHandle || '';

  useEffect(() => {
    const p = pathname;
    if (p=="/" || p=="") {
      setBottomMenu(BottomMenu.Start);
    }else{
      setBottomMenu(BottomMenu.Challenges);
    }
  }, [pathname, setBottomMenu]);

  return (
    <Tabs
      onClick={(e) => {
        console.log(e);
      }}
      value={bottomMenu}
      onChange={(evt, value) => {
        switch (value) {
          case BottomMenu.Start:
            navigate('/');
            return;
          case BottomMenu.Galleries:
            navigate('/galleries');
            return;
          case BottomMenu.Gallery:
            navigate('/album/' + albumId);
            return;
          case BottomMenu.Upload:
            setUploadAlbum({
              albumId:
                (bottomMenu === BottomMenu.Gallery ||
                  bottomMenu === BottomMenu.Scroller) &&
                albumId !== challengeAlbumId
                  ? albumId
                  : 0,
              open: true,
              onClose: () => {
                setUploadAlbum(undefined);
              },
              onFinish: () => {
                setRefreshAlbum((prev) => prev + 1);
                setUploadAlbum(undefined);
              },
            });
            return;
          case BottomMenu.Scroller:
            navigate('/album/' + albumId + '/scroller/' + postHandle);
            return;
          case BottomMenu.Challenges:
            navigate('/challenges');
            return;
        }
      }}
      className={'scroller-bottom-navigation'}
    >
      <Tab
        value={BottomMenu.Start}
        icon={bottomMenu === BottomMenu.Start ? <IoHome /> : <IoHomeOutline />}
      />

      <Tab
        value={BottomMenu.Challenges}
        icon={
          bottomMenu === BottomMenu.Challenges ? (
            <IoImages />
          ) : (
            <IoImagesOutline />
          )
        }
      />

    </Tabs>
  );
};
