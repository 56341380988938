/* eslint-disable */
/**
 * v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {Guid, StringDate} from '@customTypes'
import url from 'url'
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@img/http-client";

import { CheckResponseDto } from '../model/checkResponseDto';
import { CheckUploadedState } from '../model/checkUploadedState';
import { CropParameters2Dto } from '../model/cropParameters2Dto';
import { CropResultDto } from '../model/cropResultDto';
import { CroppedImageDto } from '../model/croppedImageDto';
import { SasBlobDto } from '../model/sasBlobDto';
import { SasBlobRequestDto } from '../model/sasBlobRequestDto';
import { StringStringValuesKeyValuePair } from '../model/stringStringValuesKeyValuePair';
import { UploadFinishedDo } from '../model/uploadFinishedDo';



export class UploadApiService {

    constructor(protected httpClient: HttpClient) {

    }


	/**
	 * 
	 * @param {CheckUploadedState} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // UploadController.CheckFiles  (EventImages.Server.Controllers)
     // uploadsCheckPost
     public checkFiles_POST(body?: CheckUploadedState, options: any = {}): Promise<ResponseWrap<CheckResponseDto>> {
		const _path = `/uploads/check`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"CheckUploadedState" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<CheckResponseDto>('Upload', 'uploadsCheckPost', httpOptions);
	}


	/**
	 * 
	 * @param {StringStringValuesKeyValuePair[]} [formCollection] 
	 * @param {string} [zoom] 
	 * @param {number} [width] 
	 * @param {number} [height] 
	 * @param {number} [x] 
	 * @param {number} [y] 
	 * @param {number} [rotation] 
	 * @param {string} [aspectRatio] 
	 * @param {string} [cropShape] 
	 * @param {number} [newMaxLength] 
	 * @param {string} [fileName] 
	 * @param {number} [fileSize] 
	 * @param {string} [lastModified] 
	 * @param {string} [fileType] 
	 * @param {boolean} [createVariants] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // UploadController.UploadCrop  (EventImages.Server.Controllers)
     // uploadsCropPost
     public uploadCrop_POST(formCollection?: StringStringValuesKeyValuePair[], zoom?: string, width?: number, height?: number, x?: number, y?: number, rotation?: number, aspectRatio?: string, cropShape?: string, newMaxLength?: number, fileName?: string, fileSize?: number, lastModified?: string, fileType?: string, createVariants?: boolean, options: any = {}): Promise<ResponseWrap<CropResultDto>> {
		const _path = `/uploads/crop`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;
		const formParams = new url.URLSearchParams();

		if (formCollection) {
				formParams.set('formCollection', formCollection.join(COLLECTION_FORMATS[""]));
		}

		if (zoom !== undefined) {
			formParams.set('zoom', zoom as any);
		}

		if (width !== undefined) {
			formParams.set('width', width as any);
		}

		if (height !== undefined) {
			formParams.set('height', height as any);
		}

		if (x !== undefined) {
			formParams.set('x', x as any);
		}

		if (y !== undefined) {
			formParams.set('y', y as any);
		}

		if (rotation !== undefined) {
			formParams.set('rotation', rotation as any);
		}

		if (aspectRatio !== undefined) {
			formParams.set('aspectRatio', aspectRatio as any);
		}

		if (cropShape !== undefined) {
			formParams.set('cropShape', cropShape as any);
		}

		if (newMaxLength !== undefined) {
			formParams.set('newMaxLength', newMaxLength as any);
		}

		if (fileName !== undefined) {
			formParams.set('fileName', fileName as any);
		}

		if (fileSize !== undefined) {
			formParams.set('fileSize', fileSize as any);
		}

		if (lastModified !== undefined) {
			formParams.set('lastModified', lastModified as any);
		}

		if (fileType !== undefined) {
			formParams.set('fileType', fileType as any);
		}

		if (createVariants !== undefined) {
			formParams.set('createVariants', createVariants as any);
		}

		headerParameter['Content-Type'] = 'application/x-www-form-urlencoded';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		requestOptions.body = formParams.toString();

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<CropResultDto>('Upload', 'uploadsCropPost', httpOptions);
	}


	/**
	 * 
	 * @param {number} croppedImageId 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // UploadController.GetCroppedImageDto  (EventImages.Server.Controllers)
     // uploadsCropedimageCroppedImageIdPost
     public getCroppedImageDto_POST(croppedImageId: number, options: any = {}): Promise<ResponseWrap<CroppedImageDto>> {
		// verify required parameter 'croppedImageId' is not null or undefined
		if (croppedImageId === null || croppedImageId === undefined) {
			throw new RequiredError('croppedImageId','Required parameter croppedImageId was null or undefined when calling uploadsCropedimageCroppedImageIdPost.');
		}
		const _path = `/uploads/cropedimage/{croppedImageId}`
			.replace(`{${"croppedImageId"}}`, encodeURIComponent(String(croppedImageId)));
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<CroppedImageDto>('Upload', 'uploadsCropedimageCroppedImageIdPost', httpOptions);
	}


	/**
	 * 
	 * @param {UploadFinishedDo} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // UploadController.Finished  (EventImages.Server.Controllers)
     // uploadsFinishedPost
     public finished_POST(body?: UploadFinishedDo, options: any = {}): Promise<ResponseWrap<CheckResponseDto>> {
		const _path = `/uploads/finished`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"UploadFinishedDo" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<CheckResponseDto>('Upload', 'uploadsFinishedPost', httpOptions);
	}


	/**
	 * 
	 * @param {StringStringValuesKeyValuePair[]} [formCollection] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // UploadController.GetPreviewImage  (EventImages.Server.Controllers)
     // uploadsJpegPost
     public getPreviewImage_POST(formCollection?: StringStringValuesKeyValuePair[], options: any = {}): Promise<ResponseWrap<string>> {
		const _path = `/uploads/jpeg`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;
		const formParams = new url.URLSearchParams();

		if (formCollection) {
				formParams.set('formCollection', formCollection.join(COLLECTION_FORMATS[""]));
		}

		headerParameter['Content-Type'] = 'application/x-www-form-urlencoded';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		requestOptions.body = formParams.toString();

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<string>('Upload', 'uploadsJpegPost', httpOptions);
	}


	/**
	 * 
	 * @param {SasBlobRequestDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // UploadController.GetSas  (EventImages.Server.Controllers)
     // uploadsPost
     public getSas_POST(body?: SasBlobRequestDto, options: any = {}): Promise<ResponseWrap<SasBlobDto>> {
		const _path = `/uploads`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"SasBlobRequestDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<SasBlobDto>('Upload', 'uploadsPost', httpOptions);
	}


	/**
	 * 
	 * @param {CropParameters2Dto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // UploadController.RecropAvatar  (EventImages.Server.Controllers)
     // uploadsRecropPost
     public recropAvatar_POST(body?: CropParameters2Dto, options: any = {}): Promise<ResponseWrap<CropResultDto>> {
		const _path = `/uploads/recrop`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"CropParameters2Dto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<CropResultDto>('Upload', 'uploadsRecropPost', httpOptions);
	}


}
