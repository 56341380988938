/* eslint-disable */
/**
 * v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {Guid, StringDate} from '@customTypes'
import url from 'url'
import { HttpOptions, HttpClient, ResponseWrap, RequiredError, COLLECTION_FORMATS } from "@img/http-client";

import { AlbumDetailDto } from '../model/albumDetailDto';
import { CommentDto } from '../model/commentDto';
import { CreateAlbumDto } from '../model/createAlbumDto';
import { CreateAlbumResponseDto } from '../model/createAlbumResponseDto';
import { DeleteRequest } from '../model/deleteRequest';
import { GroupedPostResultDto } from '../model/groupedPostResultDto';
import { LikeRequestDto } from '../model/likeRequestDto';
import { NewCommentRequestDto } from '../model/newCommentRequestDto';
import { PostActionsDto } from '../model/postActionsDto';
import { PostDto } from '../model/postDto';
import { PostQueryDto } from '../model/postQueryDto';
import { PostsDto } from '../model/postsDto';
import { QueryAlbumDto } from '../model/queryAlbumDto';
import { QueryAlbumResponseDto } from '../model/queryAlbumResponseDto';
import { UpdateAlbumDto } from '../model/updateAlbumDto';



export class PostApiService {

    constructor(protected httpClient: HttpClient) {

    }


	/**
	 * 
	 * @param {PostActionsDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.Actions  (EventImages.Server.Controllers)
     // actionsPost
     public actions_POST(body?: PostActionsDto, options: any = {}): Promise<ResponseWrap<Response>> {
		const _path = `/actions`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"PostActionsDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetchWithoutResponse('Post', 'actionsPost', httpOptions);
	}


	/**
	 * 
	 * @param {number} albumId 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.GetAlbum  (EventImages.Server.Controllers)
     // albumsAlbumIdGet
     public getAlbum_GET(albumId: number, options: any = {}): Promise<ResponseWrap<AlbumDetailDto>> {
		// verify required parameter 'albumId' is not null or undefined
		if (albumId === null || albumId === undefined) {
			throw new RequiredError('albumId','Required parameter albumId was null or undefined when calling albumsAlbumIdGet.');
		}
		const _path = `/albums/{albumId}`
			.replace(`{${"albumId"}}`, encodeURIComponent(String(albumId)));
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'GET' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<AlbumDetailDto>('Post', 'albumsAlbumIdGet', httpOptions);
	}


	/**
	 * 
	 * @param {number} albumId 
	 * @param {UpdateAlbumDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.UpdateAlbum  (EventImages.Server.Controllers)
     // albumsAlbumIdPost
     public updateAlbum_POST(albumId: number, body?: UpdateAlbumDto, options: any = {}): Promise<ResponseWrap<AlbumDetailDto>> {
		// verify required parameter 'albumId' is not null or undefined
		if (albumId === null || albumId === undefined) {
			throw new RequiredError('albumId','Required parameter albumId was null or undefined when calling albumsAlbumIdPost.');
		}
		const _path = `/albums/{albumId}`
			.replace(`{${"albumId"}}`, encodeURIComponent(String(albumId)));
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"UpdateAlbumDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<AlbumDetailDto>('Post', 'albumsAlbumIdPost', httpOptions);
	}


	/**
	 * 
	 * @param {QueryAlbumDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.GetAlbums  (EventImages.Server.Controllers)
     // albumsListPost
     public getAlbums_POST(body?: QueryAlbumDto, options: any = {}): Promise<ResponseWrap<QueryAlbumResponseDto>> {
		const _path = `/albums/list`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"QueryAlbumDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<QueryAlbumResponseDto>('Post', 'albumsListPost', httpOptions);
	}


	/**
	 * 
	 * @param {CreateAlbumDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.CreateAlbum  (EventImages.Server.Controllers)
     // albumsPost
     public createAlbum_POST(body?: CreateAlbumDto, options: any = {}): Promise<ResponseWrap<CreateAlbumResponseDto>> {
		const _path = `/albums`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"CreateAlbumDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<CreateAlbumResponseDto>('Post', 'albumsPost', httpOptions);
	}


	/**
	 * 
	 * @param {DeleteRequest} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.DeleteItems  (EventImages.Server.Controllers)
     // deletePost
     public deleteItems_POST(body?: DeleteRequest, options: any = {}): Promise<ResponseWrap<Response>> {
		const _path = `/delete`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"DeleteRequest" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetchWithoutResponse('Post', 'deletePost', httpOptions);
	}


	/**
	 * 
	 * @param {PostQueryDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.GetGroupedPosts  (EventImages.Server.Controllers)
     // groupedPostsPost
     public getGroupedPosts_POST(body?: PostQueryDto, options: any = {}): Promise<ResponseWrap<GroupedPostResultDto>> {
		const _path = `/grouped/posts`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"PostQueryDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<GroupedPostResultDto>('Post', 'groupedPostsPost', httpOptions);
	}


	/**
	 * 
	 * @param {NewCommentRequestDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.Comment  (EventImages.Server.Controllers)
     // postsCommentsPost
     public comment_POST(body?: NewCommentRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
		const _path = `/posts/comments`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"NewCommentRequestDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetchWithoutResponse('Post', 'postsCommentsPost', httpOptions);
	}


	/**
	 * 
	 * @param {Guid} handle 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.Comments  (EventImages.Server.Controllers)
     // postsHandleCommentsGet
     public comments_GET(handle: Guid, options: any = {}): Promise<ResponseWrap<CommentDto[]>> {
		// verify required parameter 'handle' is not null or undefined
		if (handle === null || handle === undefined) {
			throw new RequiredError('handle','Required parameter handle was null or undefined when calling postsHandleCommentsGet.');
		}
		const _path = `/posts/{handle}/comments`
			.replace(`{${"handle"}}`, encodeURIComponent(String(handle)));
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'GET' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<CommentDto[]>('Post', 'postsHandleCommentsGet', httpOptions);
	}


	/**
	 * 
	 * @param {LikeRequestDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.Like  (EventImages.Server.Controllers)
     // postsLikePost
     public like_POST(body?: LikeRequestDto, options: any = {}): Promise<ResponseWrap<Response>> {
		const _path = `/posts/like`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"LikeRequestDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetchWithoutResponse('Post', 'postsLikePost', httpOptions);
	}


	/**
	 * 
	 * @param {PostQueryDto} [body] 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.GetPosts  (EventImages.Server.Controllers)
     // postsPost
     public getPosts_POST(body?: PostQueryDto, options: any = {}): Promise<ResponseWrap<PostsDto>> {
		const _path = `/posts`;
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		headerParameter['Content-Type'] = 'application/json';

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);
		const needsSerialization = (<any>"PostQueryDto" !== "string") || requestOptions.headers['Content-Type'] === 'application/json';
		requestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<PostsDto>('Post', 'postsPost', httpOptions);
	}


	/**
	 * 
	 * @param {Guid} postHandle 
	 * @param {*} [options] Override http request option.
	 * @throws {RequiredError}
	 */
     // PostController.GetPost  (EventImages.Server.Controllers)
     // postsPostHandlePost
     public getPost_POST(postHandle: Guid, options: any = {}): Promise<ResponseWrap<PostDto>> {
		// verify required parameter 'postHandle' is not null or undefined
		if (postHandle === null || postHandle === undefined) {
			throw new RequiredError('postHandle','Required parameter postHandle was null or undefined when calling postsPostHandlePost.');
		}
		const _path = `/posts/{postHandle}`
			.replace(`{${"postHandle"}}`, encodeURIComponent(String(postHandle)));
		const urlObj = url.parse(_path, true);
		const requestOptions = Object.assign({ method: 'POST' }, options);
		const headerParameter = {} as any;
		const queryParameter = {} as any;

		urlObj.query = Object.assign({}, urlObj.query, queryParameter, options.query);
		// fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
		// delete urlObj.search;
		requestOptions.headers = Object.assign({}, headerParameter, options.headers);

		const httpOptions : HttpOptions={
			url: url.format(urlObj),
			options: requestOptions,
		};

		return this.httpClient.fetch<PostDto>('Post', 'postsPostHandlePost', httpOptions);
	}


}
