import { Link } from 'react-router-dom';
import React from 'react';
import { PostModel, PostModelType } from '../../utils/post.models';
import { SelectionMode, useEventContext } from '@event-images/shared';
import {
  IoAddCircleOutline,
  IoChatboxEllipsesOutline,
  IoHeartOutline,
} from 'react-icons/io5';
import { useLongPress } from 'use-long-press';
import { IoTimeOutline } from 'react-icons/io5';
import { EType } from '@backend/models';
import { IoPlayOutline } from 'react-icons/io5';
import { IoEllipseSharp } from 'react-icons/io5';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { IoCheckmarkCircle } from 'react-icons/io5';

interface OverviewItemProps {
  post: PostModel;
  albumId: number;
  editable: boolean;
}

export const MobileOverviewItem = (props: OverviewItemProps) => {
  const {
    setUploadAlbum,
    setRefreshAlbum,
    selectionMode,
    setSelectionMode,
    changeSelectedPosts,
    selectedPostsState,
  } = useEventContext();
  const postModel = props.post;
  const post = postModel.data;

  const isSelecting = selectionMode === SelectionMode.Selection;

  if (postModel.type === PostModelType.Upload) {
    if (isSelecting) {
      return null;
    }
    return (
      <div
        className={'post-grid post-grid-upload'}
        onClick={() => {
          setUploadAlbum({
            albumId: props.albumId,
            open: true,
            onClose: () => {
              setUploadAlbum(undefined);
            },
            onFinish: () => {
              setRefreshAlbum((prev) => prev + 1);
              setUploadAlbum(undefined);
            },
          });
        }}
      >
        <div>
          <IoAddCircleOutline />
          <div>
            Bilder / Videos
            <br />
            Hochladen
          </div>
        </div>
      </div>
    );
  }
  if (postModel.data.waiting) {
    return (
      <div className={'post-grid post-grid-upload'}>
        <div>
          <IoTimeOutline />
          <div>Warte auf Veröffentlichung</div>
        </div>
      </div>
    );
  }
  const isSelected =
    props.editable && isSelecting && selectedPostsState[post.handle];

  if (isSelecting) {
    return (
      <div
        className={'post-grid'}
        key={post.handle}
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          changeSelectedPosts({
            postHandle: post.handle,
          });
        }}
      >
        <img src={post.images?.thumb} data-post={post.handle} />
        {isSelecting && !isSelected && (
          <div className={'post-selecting'}>
            <IoEllipseSharp />{' '}
          </div>
        )}
        {isSelecting && isSelected && (
          <div className={'post-selected'}>
            <IoCheckmarkCircle />{' '}
          </div>
        )}
        <div className={'post-details'}>
          {post.likes > 0 && (
            <>
              {post.likes}
              <IoHeartOutline />
            </>
          )}{' '}
          {post.commentsCount > 0 && (
            <>
              {post.commentsCount}
              <IoChatboxEllipsesOutline />
            </>
          )}
          {post.type == EType.Video && (
            <>
              <IoPlayOutline />
            </>
          )}
        </div>
      </div>
    );
  }
  return (
    <Link
      to={'/album/' + post.albumId + '/scroller/' + post.handle}
      className={'post-grid'}
      key={post.handle}
    >
      <img src={post.images?.thumb} data-post={post.handle} />
      {isSelecting && !isSelected && (
        <div className={'post-selecting'}>
          <IoEllipseSharp />{' '}
        </div>
      )}
      {isSelecting && isSelected && (
        <div className={'post-selected'}>
          <IoCheckmarkCircle />{' '}
        </div>
      )}
      <div className={'post-details'}>
        {post.likes > 0 && (
          <>
            {post.likes}
            <IoHeartOutline />
          </>
        )}{' '}
        {post.commentsCount > 0 && (
          <>
            {post.commentsCount}
            <IoChatboxEllipsesOutline />
          </>
        )}
        {post.type == EType.Video && (
          <>
            <IoPlayOutline />
          </>
        )}
      </div>
    </Link>
  );
};
