import {
  Group,
  GroupedPostResult,
  GroupModel,
  PostModel,
  PostModelType,
  PostRow,
  PostsModel,
} from './post.models';
import { Api } from '@backend/api';
import { SortMode } from '@event-images/shared';
import moment from 'moment';
import { ChallengeDetailDto, PostQueryDto } from '@backend/models';

interface ChallengeCacheModel {
  challenges: ChallengeDetailDto[];
  cacheTimestampSec: number;
}


const cache: { [key: string]: ChallengeCacheModel } = {};

const _loadChallenges = async (
  api: Api,
  forceReload :boolean
): Promise<ChallengeDetailDto[]> => {
  const cacheKey = 'k';
  const cacheEntry = cache[cacheKey];
  const nowInSec = new Date().getTime() / 1000;

  if (!forceReload && cacheEntry && cacheEntry.cacheTimestampSec > nowInSec - 5 * 60) {
    return cacheEntry.challenges;
  }
  const _challenges = await api.challengeApiService.getChallenges_POST();
  const z=_challenges.result?.challenges||[];

  z.sort((a, b) => {
    return a.id > b.id ? 1 : -1;
  });

  if (z.length) {
    cache[cacheKey] = {
      challenges: z,
      cacheTimestampSec: nowInSec,
    };
  }
  return z;
};


export const loadChallenges = async (
  api: Api,
  forceReload = false,
): Promise<ChallengeDetailDto[]> => {
  const challenges = await _loadChallenges(api,  forceReload);

  return challenges;
};
