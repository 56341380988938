import React, { useEffect } from 'react';
import { useApi } from '@backend/api';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './timeline.scss';

import Carriage from '../../svg/wedding-icons/__Carriage.svg';
import Balloons from '../../svg/wedding-icons/__Balloons.svg';
import Weddingarch1 from '../../svg/wedding-icons/__Weddingarch1.svg';
import Weddingcake2 from '../../svg/wedding-icons/__Weddingcake2.svg';
import Ringsheart from '../../svg/wedding-icons/__Ringsheart.svg';
import Grooms from '../../svg/wedding-icons/__Grooms2.svg';
import Toast from '../../svg/wedding-icons/__Toast.svg';
import Photos from '../../svg/wedding-icons/__Photos.svg';
import Camera from '../../svg/wedding-icons/__Camera.svg';
import Flower from '../../svg/wedding-icons/__Flower.svg';
import Silverplatter from '../../svg/wedding-icons/__Silverplatter.svg';
import Glitterball2 from '../../svg/wedding-icons/__Glitterball2.svg';
import Bouquet from '../../svg/wedding-icons/__Bouquet.svg';
import Party from '../../svg/wedding-icons/__Heartsconfetti.svg';
import { useEventContext } from '@event-images/shared';
import { AlbumSelection } from '../../components/album-selection/AlbumSelection';
import Lottie from 'lottie-react';
import flowerRightAnimation from '../../lottie/flower-right.json';

export interface MTimeLineProps {}

const myIcons: any = {
  Carriage: <Carriage />,
  Balloons: <Balloons />,
  Weddingarch: <Weddingarch1 />,
  Weddingcake: <Weddingcake2 />,
  Toast: <Toast />,
  Ringsheart: <Ringsheart />,
  Grooms: <Grooms />,
  Photos: <Photos />,
  Camera: <Camera />,
  Flower: <Flower />,
  Silverplatter: <Silverplatter />,
  Glitterball2: <Glitterball2 />,
  Bouquet: <Bouquet />,
  Party: <Party />,
};

export interface MTimeLine {
  id: number;
  time: string;
  title: string;
  icon: string;
}

const myTimeLine: MTimeLine[] = [
  {
    id: 10,
    time: '14:00',
    title: 'Eintreffen der Gäste',
    icon: 'Carriage',
  },
  {
    id: 20,
    time: '14:30',
    title: 'Trauung',
    icon: 'Ringsheart',
  },
  {
    id: 30,
    time: '15:15',
    title: 'Agape',
    icon: 'Toast',
  },
  {
    id: 40,
    time: '16:00',
    title: 'Paarshooting',
    icon: 'Grooms',
  },
  {
    id: 60,
    time: '17:00',
    title: 'Gruppenshooting',
    icon: 'Camera',
  },
  {
    id: 70,
    time: '17:30',
    title: 'Luftballone steigen lassen',
    icon: 'Balloons',
  },
  {
    id: 80,
    time: '17:50',
    title: 'Überraschung Familie Berger',
    icon: 'Flower',
  },
  {
    id: 90,
    time: '18:30',
    title: 'Reden + Eröffnung Buffett',
    icon: 'Silverplatter',
  },
  {
    id: 100,
    time: '20:30',
    title: 'Eröffnungstanz',
    icon: 'Glitterball2',
  },
  {
    id: 110,
    time: '21:30',
    title: 'Hochzeitstorte',
    icon: 'Weddingcake',
  },
  {
    id: 120,
    time: '23:00',
    title: 'Brautstrauß werfen',
    icon: 'Bouquet',
  },
  {
    id: 13,
    time: '',
    title: 'Party',
    icon: 'Party',
  },
];

export const MyTimeLine = (props: MTimeLineProps) => {
  const api = useApi();
  const { setCurrentTitle, albums } = useEventContext();

  useEffect(() => {
    setCurrentTitle(undefined);
  }, [setCurrentTitle]);
  if (!albums?.length) {
    return null;
  }
  return (
    <div className={'startpage'}>
      <div className={'text-block-2 text-block-2a'}>
        <div className={'header-title'}>Willkommen!</div>
        <p>
          Hier findest Du die Fotos & Challenges unserer
          Hochzeit.
        </p>
        <p>Du kannst Fotos ansehen, hochladen, kommentieren, liken, sowie deine auch wieder löschen.</p>
        <p>Unter "Eigene Bilder" findest alle Deine Uploads aus den Alben.</p>
        <p>Viel Spaß!</p>
        <p></p>
        <p>Christan & Reinhard</p>
        <Lottie
          className={'flowers'}
          animationData={flowerRightAnimation}
          loop={false}
        />
      </div>

      <div className={'timeline-container'}>
        <div className={'header-title'}>Hochzeits-Ablauf</div>

        <VerticalTimeline lineColor={'#ffffff'}>
          {myTimeLine.map((item) => {
            return (
              <VerticalTimelineElement
                key={'x' + item.id}
                date={'' + item.time}
                iconStyle={{}}
                icon={myIcons[item.icon]}
              >
                <h3 className="vertical-timeline-element-title">
                  {item.title}
                </h3>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
        <div className={'made-with-love-1'}>Made with ❤️ in Vienna</div>
        <div className={'made-with-love-2'}>2024</div>
      </div>
    </div>
  );
};
